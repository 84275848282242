import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
  setDefaultModal,
  setLoading,
  setModal,
} from 'src/actions/element'
import {handleError} from 'src/helpers/errorHandler'
import sendRequest from 'src/helpers/service'

import cm from './supplierCard.module.css'

const Product = ({product, PromotionID, Props}) => {
  const headers = useSelector((state) => state.data.headers)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const handleClick = () => {
    // console.log(Props)
    if (Props.RetailerSelectionTypeID === 1) {
      product.RetailerSelectionTypeID = 1
      dispatch(
        setModal({
          type: 'gift-list',
          PromotionID: PromotionID,
          RetailerData: product,
        })
      )
    } else if (Props.RetailerSelectionTypeID === 3) {
      // console.log(product)
      dispatch(
        setDefaultModal({
          title: product.Title,
          message:
            headers.supplierRetailerWebSelectionPopupMessage ||
            'Bu tedarikçiyi seçmek istediğinize emin misiniz?',
          button: headers.supplierRetailerWebSelectionPopupButton || 'Katıl',
          onClick: handleSubmit,
        })
      )
    } else if (Props.RetailerSelectionTypeID === 2) {
      // console.log(product)
      dispatch(
        setDefaultModal({
          title: product.Title,
          message:
            headers.supplierRetailerWebSelectionPopupMessage ||
            'Bu tedarikçiyi seçmek istediğinize emin misiniz?',
          button: headers.supplierRetailerWebSelectionPopupButton || 'Katıl',
          onClick: handleSubmit,
        })
      )
    }
    // else if (snacking)
  }
  const handleSubmit = () => {
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/ParticipatePromotionSeasonOpening', {
        CustomerID: user.CustomerID,
        PromotionID: PromotionID,
        RetailerID: product.RetailerID,
      })
      .then((data) => {
        // console.log(data)
        dispatch(setLoading(false))
        if (data[0].ResponseCode === 0) {
          dispatch(
            setDefaultModal({
              title:
                headers.supplierGiftSelectionSuccessPopupTitle ||
                'Teşekkür Ederiz',
              message: data.Message || 'Katılımınız alınmıştır.',
              button: 'TAMAM',
              onClick: handleSuccess,
              onClose: handleSuccess,
            })
          )
        } else {
          // console.log(data)
          dispatch(setLoading(false))
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(setLoading(false))
        handleError(error, dispatch)
      })
  }
  const handleSuccess = () => {
    dispatch(setModal(false))
  }
  return (
    <div
      onClick={() => {
        product.Quantity > 0 && handleClick()
      }}
      className={product.Quantity < 1 ? cm.stockOutContainer : cm.container}
    >
      <div className={cm.top}>
        <div className={cm.topLeft}>
          <img className={cm.image} src={product.Image} alt='' />
          <div className={cm.textCon}>
            <div className={cm.name}>{product.Title}</div>
            <div className={cm.name2}>{product.Description}</div>
          </div>
        </div>
        <div></div>
      </div>
      <div className={cm.bottom}>
        {product.Quantity > 0 ? (
          <span className={cm.buttonText}>{product.ButtonText}</span>
        ) : (
          <div className={cm.bottomDis}>
            <span className={cm.buttonText}>
              {headers.supplierOutOfStock || 'Ürünün stoğu bitmiştir.'}
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                }}
              />
              <p className={cm.buttonText}>
                {headers.supplierClosed || 'Kapalı'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Product
