import React from 'react'
import styles from './versionCard.module.css'
import packageJson from '../../../package.json'
const version = packageJson.version

function VersionCard() {
  return (
    <div className={styles.card}>
      v&nbsp;{version}
    </div>
  )
}

export default VersionCard