import axios from 'axios'
import {setErrorModal, setLoading} from '../actions/element'
import {logout} from '../actions/user'

import {appKey, appKeyMagnum} from './../constants/values'

const sendRequest = {
  post: (url: string, data: any, headers?: object | null, magnum = false) => handleRequest(url, data, 'POST', headers, magnum),
  get: (url: string, data: any) => handleRequest(url, data, 'GET'),
}

export default sendRequest

function handleRequest(url: string, data: any, method: any, headers = {}, magnum = false) {
  if (method === 'POST') {
    if (!data) data = {}
    
    data.appKey = magnum ? appKeyMagnum : appKey
  }

  return new Promise((resolve, reject) => {
    request
      .request({
        method,
        url,
        data,
        //  headers,
      })
      .then((res) => {
        resolve(res?.data)
      })
      .catch((err) => reject(err))
  })
}

const env = localStorage.getItem('env')
const request = axios.create({
  //baseURL: "https://devapi-kazan.algida.me/",
  // baseURL: 'https://testapi-kazan.algida.me/',
  baseURL: 'https://prodapi-kazan.algida.me/',
  // baseURL: 'https://keel.algida.me/',
  // baseURL:
  //   env === 'test' || env === 'dev'
  //     ? 'https://testapi.algida.me/'
  //     : 'https://keel.algida.me/',
  withCredentials: false,
})

const TIMEOUT = 30000
let CancelToken = axios.CancelToken
let source = CancelToken.source()
let cancel: string | number | NodeJS.Timeout = null
let token = null
request.interceptors.request.use(async function (config) {
  try {
    // console.log("@congif içerisindeki url", config.url);
    config.cancelToken = source.token
    if (!config.url.includes('getStores')) {
      cancel = setTimeout(() => {
        source.cancel()
        clearTimeout(cancel)
        CancelToken = axios.CancelToken
        source = CancelToken.source()
        cancel = null
      }, TIMEOUT)
    }

    token = localStorage.getItem('t')
    config.headers.common.Authorization = 'Bearer ' + token

    if (window?.captchaToken && env !== 'dev')
      config.headers.common.CaptchaToken = window.captchaToken

    clearTimeout(cancel)
  } catch (err) {
    console.log('Apiv1  Hata: ', err)
    clearTimeout(cancel)
  }
  return config
})

request.interceptors.response.use(
  async function (config) {
    clearTimeout(cancel)
    return config
  },
  async function (error) {
    if (error && error.response && error.response.status === 401) {
      try {
        let ref = localStorage.getItem('r_t')
        let id = localStorage.getItem('c_i')

        let obj = {
          CustomerID: id,
          RefreshToken: ref,
          appKey,
        }
        let getToken = await request.request({
          url: 'electra/api/promo/refreshToken',
          method: 'POST',
          data: obj,
        })

        if (getToken && getToken.data && getToken.data[0]) {
          localStorage.setItem('t', getToken.data[0].Token)
          localStorage.setItem('r_t', getToken.data[0].RefreshToken)

          let cf = error.config
          cf.headers.Authorization = 'Bearer ' + getToken.data[0].Token
          let repeat = await request.request(cf)
          return Promise.resolve(repeat)
        } else {
          console.log('test')
          logoutAction()
          throw false //eslint-disable-line
        }
      } catch (err) {
        logoutAction()
        console.log('get token fail', err)
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  }
)

function logoutAction() {
  localStorage.clear()
  window.storeDispatch(logout())
  window.storeDispatch(setLoading(false))
  window.storeDispatch(
    setErrorModal({
      title: 'Çıkış Yapıldı!',
      message:
        'Oturum bilgileriniz zaman aşımına uğradı. Lütfen tekrar giriş yapın.',
    })
  )
}
