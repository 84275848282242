import {legacy_createStore as createStore, combineReducers} from 'redux'

import elements from './reducers/elements'
import defaultModal from './reducers/defaultModal'
import user from './reducers/user'
import data from './reducers/data'
import cart from './reducers/cart'
import yazsicak from './reducers/yazsicak'

const reducers = combineReducers({
  elements,
  defaultModal,
  user,
  data,
  cart,
  yazsicak,
})

const store = createStore(reducers)
window.storeDispatch = store.dispatch

export default store
