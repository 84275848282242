import classcat from 'classcat'
import React from 'react'

import c from './checkbox.module.css'

export default function Checkbox({
  id,
  value,
  onChange,
  label,
  className,
  warn,
  children,
}) {
  return (
    <label
      id={id}
      className={classcat([c.container, warn && c.warning, className])}
    >
      {children}
      <span dangerouslySetInnerHTML={{__html: label}}></span>
      <input
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        type='checkbox'
      />
      <span className={c.checkmark} />
    </label>
  )
}
