import React from 'react'
import cc from 'classcat'
import {AsYouType} from 'libphonenumber-js'

import cm from './phoneInput.module.css'

const Input = ({
  value,
  style,
  onChange,
  placeholder,
  select,
  onFocus,
  onBlur,
  onInput,
  className,
  isMagnum = false,
}) => {
  return (
    <div
      style={style}
      className={cc([cm.container, select && cm.select, className])}
    >
      <div style={{color: isMagnum && 'white'}} className={cm.prefix}>+90</div>
      <input
        style={{backgroundColor: isMagnum && '#36160D', color: isMagnum && 'white', }}
        disabled={select}
        placeholder={placeholder}
        onInput={onInput}
        value={value}
        onKeyPress={(e) => {
          if (value.replace(/ /g, '').length === 10) {
            e.stopPropagation()
            e.preventDefault()
            return false
          }
        }}
        onChange={(e) => onChange(new AsYouType('TR').input(e.target.value))}
        className={cm.text}
        type='tel'
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
}

export default Input
