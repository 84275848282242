import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ModalTemplate from 'src/components/ModalTemplate'
import {setUser} from 'src/actions/user'
import {setModal} from 'src/actions/element'
import {isChild} from 'src/helpers/domHelper'
//import LoadingAnimation from '@src/components/LoadingAnimation'
import LoadingAnimation from "src/components/LoadingAnimation"
import c from './magnumwin.module.css'
import sadCoin from 'src/assets/images/sad_coin.png'
import goldCoin from 'src/assets/images/magnum/magnumCoin.png'
import { setDefaultModal } from 'src/actions/element'
import raffleWarningIcon from 'src/assets/images/magnum/raffleMagnumWarning.png'

export default function MagnumWinModal({onClose, data, navigate}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  async function handleSubmit() {
    window.logEvent({
      event: 'magnum_button',
      button_name: 'Çekilişe Katıl',
      UserID: user.CustomerID,
    })

    dispatch(
      setModal({
        type: 'magnum-form',
        data: data,
        navigate: (params) => navigate(params),
      })
    )
  }

  const extraCard = (extraData) => {
    return (
      <div className={c.extraContainer}>
        <div className={c.extraCard}>
          {!extraData.Point ? (
            <div className={c.extraContent}>
              <b className={c.extraFirstText}>TEŞEKKÜRLER</b>

              <img
                style={{width: 66, height: 50, marginTop: 20, marginBottom: 15}}
                src={sadCoin}
              />

              {/* <b className={c.extraSecondText}>GÜNLÜK LİMİTE TAKILDIN, HEDİYENİ KAZANMAK İÇİN YARIN KODU TEKRAR GİR.</b> */}
              <b className={c.extraSecondText}>{extraData.Title}</b>
              <b className={c.extraSecondText}>{extraData.Message}</b>
            </div>
          ) : (
            <div className={c.extraContent}>
              {data?.raffleImageUrl
                ? 'ÇEKİLİŞE KATILMA HAKKININ YANINDA'
                : 'TEBRİKLER !'}

              <img
                style={{width: 66, height: 50, marginTop: 20, marginBottom: 15}}
                src={goldCoin}
              />

              <b className={c.extraScoreText}>{extraData.Point} PUAN</b>
              <b className={c.extraWinText}>KAZANDIN!</b>
            </div>
          )}
        </div>
      </div>
    )
  }

  const showWarningModal = (onClose) => {
    dispatch(
      setDefaultModal({
        icon: raffleWarningIcon,
        iconSize: 'large',
        title: 'Dikkat!',
        message:
          'Çekilişe katılmadan çıkmak istediğine emin misin? 2 Porsche Taycan’dan veya 200.000TL değerinde Magnum Card’dan birini kazanma şansını kaçırma!',
        button: 'ÇEKİLİŞE KATIL',
        cancel: 'Kodunu Sakla ve Sonra Katıl',
        themeMagnum: true,
        onCancel: () => {
          navigate('/magnum')
          onClose()
        },
      })
    )
  }

  const handleClose = () => {
    showWarningModal(onClose)
  }


  if (!data) return <LoadingAnimation />

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={handleClose}
    >
      <>
        <div
          id='code-enter-modal-content'
          className={c.container}
          data-close={true}
          onClick={(e) => {
            if (
              window.isMobile() &&
              (!isChild(e.target, 'code-enter-modal-content') ||
                e.target.dataset.close)
            ) {
              onClose()
            }
          }}
        >
          <div className={c.headerTextCard}>
            <b className={c.headerText}>TEBRİKLER!</b>
            <b className={c.headerText}>GEÇERLİ KOD!</b>
          </div>

          {data?.raffleImageUrl && (
            <div className={c.imageContainer}>
              <img className={c.drawImage} src={data.raffleImageUrl} />
            </div>
          )}

          {data?.raffleImageUrl && (
            <div className={c.bottomCard}>
              <b className={c.bottomText}>
                Bilgilerini tamamlayarak çekilişe katılmak için TIKLA!
              </b>
            </div>
          )}

          {data?.raffleImageUrl && (
            <div className={c.buttonCard}>
              <button onClick={handleSubmit} className={c.buttonStyle}>
                ÇEKİLİŞE KATIL
              </button>
            </div>
          )}
        </div>
        {data && extraCard(data)}
      </>
    </ModalTemplate>
  )
}
