import React from 'react'
import {useSelector} from 'react-redux'

import cm from './product.module.css'

const Product = ({product, currency, onClick, lang}) => {
  const headers = useSelector((state) => state.data.headers)

  return (
    <div
      onClick={() => {
        if (product.Quantity !== 0) onClick()
      }}
      className={product.Quantity === 0 ? cm.stockOutContainer : cm.container}
    >
      <img className={cm.image} src={product.ImageUrl} alt='' />
      <div className={cm.name}>
        {product.ProductName || product.Description}
      </div>
      <div className={cm.bottom}>
        {product.Quantity !== 0 && (
          <span className={cm.amount}>{product.PriceText}</span>
        )}
        {product.Quantity === 0 && (
          <span className={cm.stockOutText}>{headers.productStockoutText}</span>
        )}
      </div>
    </div>
  )
}

export default Product
