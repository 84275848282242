import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ModalTemplate from 'src/components/ModalTemplate'
import Product from './Product'

import {
  setDefaultModal,
  setErrorModal,
  setLoading,
  setModal,
} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {setWalletState} from 'src/actions/data'
import {handleError} from 'src/helpers/errorHandler'

import c from './productList.module.css'

export default function ProductList({onClose, data}) {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [products, setProducts] = useState(data.products)

  const handleProductSelection = (product) => {
    dispatch(
      setDefaultModal({
        image: product.ImageUrl,
        message:
          'QR Kod oluşturduktan sonra seçtiğin ürünü değiştiremeyeceksin. Bu ürünü seçmek istediğine emin misin?',
        button: 'QR KOD OLUŞTUR',
        cancel: 'VAZGEÇ',
        onClick: () => saveSelectedProduct(product),
      })
    )
  }

  const saveSelectedProduct = async (product) => {
    try {
      dispatch(setLoading(true))
      const preCheck = await sendRequest.post(
        'electra/api/promo/stockPreCheck',
        {
          ProductID: product.ProductID,
          UnitPrice: product.UnitPrice,
        }
      )

      if (preCheck && preCheck[0].ResponseCode !== 0) {
        if (preCheck[0].Message)
          dispatch(
            setErrorModal({
              message: preCheck[0].Message,
            })
          )
        else
          dispatch(
            setErrorModal({
              // errorCode: 11535
            })
          )
        setDisabled(product.ProductID)
        return
      }

      const data = await sendRequest.post(
        'electra/api/promo/saveCampaignBasket',
        {
          CustomerID: user.CustomerID,
          PaymentType: 'TL',
          CampaignID: product.CampaignID || 0,
          Products: [
            {
              ProductID: product.ProductID,
              Quantity: 1,
            },
          ],
        }
      )

      window.navigate('/')
      if (data && data[0].ResponseCode === 0) {
        dispatch(
          setModal({
            type: 'gift-detail',
            data: {
              ...product,
              Title: product.ProductName,
              GiftTypeID: 1,
              GiftCode: data[0].BasketCode,
              Quantity: data[0].unitPrice,
              RedeemDescription: data[0].Message,
            },
          })
        )
        dispatch(setWalletState(false))
      } else {
        dispatch(
          setErrorModal({
            message: data && data[0].Message,
          })
        )
      }
      dispatch(setLoading(false))
    } catch (error) {
      console.log('@saveCampaignBasket service error', error)
      handleError(error, dispatch)
    }
  }

  const setDisabled = (productID) => {
    setProducts(
      products.map((product) => {
        if (product.ProductID === productID) product.Quantity = 0

        return product
      })
    )
  }

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.container}
      closeClass={c.close}
      isDrawer={false}
      onClose={() => {
        onClose()
      }}
    >
      <div className={c.top}>
        <h3 className={c.title}>1 Alana 1 Bedava</h3>
        <div className={c.subtitle}>
          Seçtiğin bir ürünü bakkal'dan tek ürün fiyatına iki adet alabilirsin.
        </div>
      </div>
      <div className={c.content}>
        {products.map((product, index) => {
          return (
            <Product
              key={'campaign-product-' + index}
              product={product}
              onClick={() => handleProductSelection(product)}
            />
          )
        })}
      </div>
    </ModalTemplate>
  )
}
