import React, {useState, useEffect} from 'react'
import classcat from 'classcat'
import {useDispatch, useSelector} from 'react-redux'

import Button from 'src/components/Button'
import {setDefaultModal, setModal} from 'src/actions/element'

import c from './win.module.css'

import raffleWarningIcon from 'src/assets/images/raffle/warning.png'
import giftDetailTopIcon from 'src/assets/images/gift-detail/top.png'
import winModalCoinIcon from 'src/assets/images/win-modal/coin.png'
import sadCoinIcon from 'src/assets/images/sad_coin.png'
// import SupplierCard from './SupplierCard'
import sendRequest from 'src/helpers/service'

export default function Win({onClose, data, navigate}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const headers = useSelector((state) => state.data.headers)
  const [showWheelResultState, setShowWheelResultState] = useState(false)
  const [wheelResult, setWheelResult] = useState({})

  let isTherePoint = data.Point && data.Point !== 0
  window.isweb = true

  const handleViewSurprise = () => {
    if(!isTherePoint){
      navigate('/')
    }
    else if (!data.GiftTypeID && isTherePoint) {
      if (data?.PromoData && data?.PromoData[0]?.ResponseCode === 0) {
        navigate('/biriktirdiklerim')
      } else {
        window.logEvent({
          event: 'redeemPoint',
          UserID: user.CustomerID,
          promo_name: data.promoName,
          PagePath: data.page,
        })
        navigate('/oduller', {
          state: {
            active: false,
          },
        })
      }
    } else {
      navigate('/biriktirdiklerim')
    }
    //TODO: RETAILER
    // if (data.PromoData) {
    //   dispatch(
    //     setDefaultModal({
    //       icon: raffleWarningIcon,
    //       iconSize: 'large',
    //       title: 'Dikkat!',
    //       message:
    //         headers.leaveSnakingMessage ||
    //         'Bu sayfadan çıkarsanız hediye hakkınızı kaybedeceksiniz.',
    //       button: headers.sanckingButton || 'Hediyeni Seç',
    //       cancel: headers.snackingCancel || 'Hediye Almadan Devam Et',
    //       onCancel: () => {
    //         onClose()
    //       },
    //     })
    //   )
    // } else {
    //   onClose()
    // }
    onClose()
  }

  const showWarningModal = () => {
    dispatch(
      setDefaultModal({
        icon: raffleWarningIcon,
        iconSize: 'large',
        title: 'Dikkat!',
        message: 'Çekilişe katılmadan çıkmak istediğine emin misin? Çekilişe katılarak büyük hediyelerden birini kazanma şansını kaçırma!',
        button: 'ÇEKİLİŞE KATIL',
        cancel: 'Kodunu Sakla ve Sonra Katıl',
        onCancel: () => {
          navigate('/biriktirdiklerim')
          onClose()
        },
      })
    )
  }

  const openRaffleForm = () => {
    dispatch(
      setModal({
        type: 'raffle',
        data,
        navigate,
      })
    )
  }

  useEffect(() => {
    data?.GameResult?.Gifts.map((item) => {
      item.Id = item.WinKey
    })
    let stringifiedData = JSON.stringify(data?.GameResult?.Gifts || '')
    localStorage.setItem('spinner', stringifiedData)

    window.onmessage = (event) => {
      console.log('on message  ', event?.data?.name)

      switch (event?.data?.name) {
        case 'spinButtonClick':
          sendRequest
            .post('electra/api/promo/ParticipateGame', {
              CustomerID: user?.CustomerID,
              GameID: data?.GameResult?.GameID,
              StickCode: data?.GameResult?.StickCode,
            })
            .then((res) => {
              setWheelResult(res[0])

              const iframe = document.querySelector('iframe')
              const iframeWin = iframe.contentWindow || iframe
              const iframeDoc = iframe.contentDocument || iframeWin.document

              var script = iframeDoc.createElement('script')
              script.append(`
                window.setWinner("${res[0].WinKey}")
              `)
              iframeDoc.documentElement.appendChild(script)
            })
            .catch((err) => {
              console.log('error', err)
            })
          break
        case 'showPrizeButtonClick':
          setShowWheelResultState(true)
          break
        default:
          break
      }
    }
  }, [])

  if (
    data?.GameResult?.GameName === 'WheelOfFortune' &&
    !showWheelResultState
  ) {
    return (
      <div
        className={classcat([c.modal, data.raffleAvailable && c.raffleModal])}
      >
        <div className={c.modalContentWheel}>
          <div className={c.contentWheel}>
            <iframe
              src={
                headers?.spinnerUrlWeb ||
                'https://algidailekazan.com/mobilephonespinner/'
              }
              className={c.wheel}
            />
          </div>
        </div>
      </div>
    )
  } else {
    data = data?.GameResult?.GameName === 'WheelOfFortune' ? wheelResult : data
    isTherePoint = data.Point && data.Point !== 0
    const onlyProduct = data?.PromoData && !data?.Point
    return (
      <div
        className={classcat([c.modal, data.raffleAvailable && c.raffleModal])}
      >
        <div className={c.modalContent}>
          <div className={c.content}>
            <img
              src={giftDetailTopIcon}
              className={c.coverImage}
              alt='giftDetailTop'
            />
            <div className={c.surprise}>
              {data?.Title
                ? data?.Title
                : !isTherePoint
                ? 'TEŞEKKÜRLER'
                : 'SÜRPRİZ'}
            </div>
            {/* {!data.GiftTypeID && data?.Message && data?.Title ? (
              <span className={c.limitMessage}>{data?.Message}</span>
            ) : null} */}
            <div className={classcat([c.productField])}>
              {data?.Title && isTherePoint ? (
                <Product
                  image={sadCoinIcon}
                  name={data?.Message}
                  noWin={true}
                />
              ) : null}
              {!data.Title ? (
                <Product
                  noWin={!(isTherePoint || data?.ProductImageUrl) ? true : false}
                  image={isTherePoint ? winModalCoinIcon : null}
                  name={isTherePoint ? data.PointText : !(isTherePoint || data?.ProductImageUrl) ? '' :  'HEDİYE DONDURMA'}
                  productImage={
                    !!data?.PromoData ? data?.ProductImageUrl :  null
                  }
                />
              ) : null}

              {data.GiftTypeID && isTherePoint ? (
                <span className={c.and}>ve</span>
              ) : null}
              {data.GiftTypeID ? (
                <Product
                  image={data.ImageUrl}
                  name={data.Message}
                  productImage={data?.ProductImageUrl}
                />
              ) : null}
            </div>
            {data?.Message && (
              <div className={c.messageCard}>{data?.Message}</div>
            )}
            <div className={c.divider} />
            {/* {!data.raffleAvailable && !onlyProduct ? (
              <div className={c.totalPoint}>Toplam Puanınız: {user.Points}</div>
            ) : null} */}
            {data?.ClassicsPromo?.Message && (
              <div className={c.productName}>
                {data?.ClassicsPromo?.Message}
              </div>
            )}

            {(!data.raffleAvailable && !onlyProduct) ||
            (!isTherePoint && !data?.GiftCode && data.ProductImageUrl) ? (
              <Button
                className={c.firstButton}
                text={
                  data.GiftTypeID
                    ? 'SÜRPRİZLERİME GİT'
                    : !data?.GiftCode && data.ProductImageUrl
                    ? 'CÜZDANA GİT'
                    : !isTherePoint ? 'TAMAM' : 'PUANLARIMI HARCA'
                }
                onClick={handleViewSurprise}
              />
            ) : null}
            {/* //TODO: RETAILER */}
            {/* {data?.PromoData?.Retailers.length > 0 && (
              <div className={c.infoMessage}>HEDİYE DONDURMAYI ALACAĞIN <br /> TEDARİKÇİYİ SEÇ</div>
            )} */}
          </div>
          {data.raffleAvailable && (
            <RaffleField
              image={data.raffleImageUrl}
              onCancel={showWarningModal}
              onClick={openRaffleForm}
            />
          )}
          {/* //TODO: RETAILER */}
          {/* {data?.PromoData?.Retailers.map((item) => {
            return (
              <SupplierCard
                product={item}
                Stickcode={data.code}
                PromoData={data?.PromoData}
                navigate={navigate}
              />
            )
          })} */}
        </div>
      </div>
    )
  }
}

function RaffleField({image, onClick, onCancel}) {
  return (
    <div className={classcat([c.content, c.raffleContent])}>
      <img className={c.raffleImage} src={image} alt='raffle' />
      <div className={c.raffleTitle}>
        Çekilişe katılmak için hak kazandınız!
      </div>
      <Button
        className={classcat([c.button, c.raffleButton])}
        text='ÇEKİLİŞE KATIL'
        onClick={onClick}
      />
      <Button
        className={classcat([c.button, c.raffleCancelButton])}
        text='Kodunu Sakla ve Sonra Katıl'
        textButton
        onClick={onCancel}
      />
    </div>
  )
}

const Product = ({image, name, noWin, productImage}) => {
  return (
    <div className={c.product}>
      <div className={c.imageFieldContainer}>
        {image && (
          <div className={c.imageField}>
            <img src={image} alt='product' />
          </div>
        )}
        {productImage && (
          <div className={c.imageField}>
            <img src={productImage} alt='product' />
          </div>
        )}
      </div>
      <div className={c.productName}>{name}</div>
      {!noWin && <div className={c.winText}>KAZANDIN!</div>}
    </div>
  )
}
