const initialState = {
  products: [],
  cartType: null,
  cartPts: 0,
  discountedPoints: 0,
  cartNote: '',
  cartStatus: '',
  cartAddress: {},
  searchStartDate: null,
}

let discountedPoints, tempCart, cartType, cartPts
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_CART':
      return {
        ...state,
        show: true,
      }
    case 'HIDE_CART':
      return initialState

    case 'CART_ADD':
      let found = false

      let item = {
        ProductID: action.body.ProductID,
        ProductName: action.body.ProductName,
        ImageUrl: action.body.ImageUrl,
        Value: 0,
        DiscountedValue: 0,
        Quantity: action.body.Quantity || 1,
      }

      discountedPoints = state.discountedPoints
      if (action.body.cartType === 'HOME_DELIVERY') {
        item.Value = action.body.UnitPrice || action.body.Value
      } else {
        item.Value = action.body.Point || action.body.Value
        if (action.body.Discount) {
          item.Discount = true
          item.DiscountedValue =
            action.body.DiscountedPoint || action.body.DiscountedValue
          discountedPoints += parseInt(item.DiscountedValue)
        } else discountedPoints += action.body.Point || action.body.Value
      }

      cartPts = (action.body.Quantity || 1) * (state.cartPts + item.Value)

      if (action.body.cartType === 'HOME_DELIVERY') cartPts = cartPts.toFixed(2)

      tempCart = [...state.products]

      //searching for same item at cart. If there is, just quantity will be incerased
      for (let i of tempCart) {
        if (item.ProductID === i.ProductID) {
          i.Quantity += item.Quantity
          found = true
          break
        }
      }

      if (!found) {
        tempCart.push(item)
      }

      return {
        ...state,
        products: tempCart,
        cartPts,
        discountedPoints,
        added: true,
      }

    case 'CART_REMOVE':
      tempCart = [...state.products]
      cartType = state.cartType

      cartPts = parseInt(state.cartPts)
      discountedPoints = state.discountedPoints

      let productIndex = tempCart.findIndex((p) => {
        return p.ProductID === action.body.ProductID
      })

      cartPts -= tempCart[productIndex].Value * action.body.Quantity
      discountedPoints -=
        (tempCart[productIndex].DiscountedValue ||
          tempCart[productIndex].Value) * action.body.Quantity

      if (tempCart[productIndex].Quantity === action.body.Quantity)
        tempCart.splice(productIndex, 1)
      else tempCart[productIndex].Quantity -= action.body.Quantity

      if (tempCart.length === 0) return initialState
      else
        return {
          ...state,
          products: tempCart,
          cartPts,
          discountedPoints,
        }

    case 'SET_CART_TYPE':
      return {
        ...state,
        cartType: action.body,
      }
    case 'SET_CART_NOTE':
      return {
        ...state,
        cartNote: action.body,
      }
    case 'RESET_CART':
      return initialState
    case 'SET_CART_STATUS':
      if (action.body === 'DELIVERED' || action.body === 'CANCELLED') {
        return {
          ...initialState,
          cartStatus: action.body,
        }
      } else {
        return {
          ...state,
          cartStatus: action.body,
        }
      }
    case 'SET_CART_ADDRESS':
      return {
        ...state,
        cartAddress: action.body,
      }
    case 'SET_START_DATE':
      return {
        ...state,
        searchStartDate: action.body,
      }
    default:
      return state
  }
}
