import React, { useCallback, useState } from 'react'
import arrowIcon from 'src/assets//images/profile/tab-arrow-active.png'
import magnumArrowIcon from 'src/assets/images/magnum/arrowDown.png'
import c from './collapsible.module.css'

const Collapsible = React.forwardRef(
  (
    {
      title,
      text,
      themeMagnum = false,
      onOpen = () => {},
      onClose = () => {}
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const handleClick = useCallback(() => {
      if (open) onClose(title)
      else onOpen(title)
      setOpen((prevState) => !prevState)
    }, [onClose, onOpen, title, open])

    return (
      <div className={c.container} style={{ background: themeMagnum && 'white' }}>
        <button className={themeMagnum ? c.collapsibleMagnumButton : c.collapsibleButton} onClick={handleClick}>
          <div style={{textAlign: 'left'}}>
            {title}
          </div>

          <div>
            {
              themeMagnum ? <img src={magnumArrowIcon} alt='arrow' className={c.arrowMagnumImage} /> : <img src={arrowIcon} alt='arrow' className={c.arrowImage} />
            }
          </div>
        </button>

        {(open && themeMagnum) && <div className={c.openedHtml} dangerouslySetInnerHTML={{__html: text}} />}
        {(open && !themeMagnum) && <div className={c.openedContainer}>{text}</div>}
      </div>
    )
  }
)

export default Collapsible
