import React from 'react'
import classcat from 'classcat'

import c from './select.module.css'

export default function Select({
  id,
  label,
  value,
  style,
  onChange,
  options,
  defaultOption,
  className,
  warn,
  warning,
  isMagnum = false,
}) {
  return (
    <div id={id} style={style} className={classcat([c.container, className])}>
      <div className={c.input}>
        {label && <label className={c.focused} >{label}</label>}
        <select style={{color: isMagnum && 'white'}} value={value} onChange={(e) => onChange(e.target.value)}>
          {defaultOption && (
            <option disabled value={defaultOption.value} >
              {defaultOption.label}
            </option>
          )}
          {options &&
            options.map((option) => {
              return (
                <option
                  key={'selectbox-option-' + option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            })}
        </select>
      </div>
      {warn && <small className={c.warning}>{warning}</small>}
    </div>
  )
}
