import React, {useEffect} from 'react'

import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from './components/Header'
import LoadingAnimation from './components/LoadingAnimation'
import sendRequest from './helpers/service'
import {handleError} from './helpers/errorHandler'
import {setModal} from './actions/element'
import {MagnumFaq} from './screens/Magnum/MagnumFaq'

const Home = React.lazy(() => import('./screens/Home'))
const Awards = React.lazy(() => import('./screens/Awards'))
const Campaigns = React.lazy(() => import('./screens/Campaigns'))
const Wallet = React.lazy(() => import('./screens/Wallet'))
const Gifts = React.lazy(() => import('./screens/Gifts'))
const Draws = React.lazy(() => import('./screens/Draws'))
const Cart = React.lazy(() => import('./screens/Cart'))
const Map = React.lazy(() => import('./screens/Map'))
const Magnum = React.lazy(() => import('./screens/Magnum'))

const Login = React.lazy(() => import('./screens/Login'))
const Register = React.lazy(() => import('./screens/Register'))
const TextPage = React.lazy(() => import('./screens/TextPage'))

const Profile = React.lazy(() => import('./screens/Profile'))
const Account = React.lazy(() => import('./screens/Profile/Account'))
const OrderHistory = React.lazy(() => import('./screens/Profile/OrderHistory'))
const HistoryInner = React.lazy(() => import('./screens/Profile/HistoryInner'))
const FriendSuggestion = React.lazy(() =>
  import('./screens/Profile/FriendSuggestion')
)
const Help = React.lazy(() => import('./screens/Profile/Help'))
const HelpInner = React.lazy(() => import('./screens/Profile/HelpInner'))

const NotFoundPage = React.lazy(() => import('./screens/NotFound'))

export default function Navigation() {
  const user = useSelector((state) => state.user)

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/giris-yap' element={<Login />} exact />
        <Route path='/uye-ol' element={<Register />} exact />
        <Route path='/yakindaki-marketler' element={<Map />} exact />

        <Route path='/' element={<Home />} exact />
        {user ? (
          <>
            <Route path='/kampanyalar' element={<Campaigns />} exact />
            <Route path='/biriktirdiklerim' element={<Wallet />} exact />
            <Route
              path='/firsatlar'
              element={<Gifts headerStatus={true} />}
              exact
            />
            <Route path='/oduller' element={<Awards />} exact />
            {/* <Route path='/cekilisler' element={<Draws />} exact /> */}
            <Route path='/kodu-nasil-kullanirim' element={<TextPage />} exact />
            <Route path='/magnum' element={<Magnum />} exact />
            <Route path='/magnumFaq' element={<MagnumFaq />} exact />
            <Route path='/profil' element={<Profile />} exact />
            <Route path='/hesabim' element={<Account />} exact />
            <Route path='/gecmis-siparisler' element={<OrderHistory />} exact />
            <Route
              path='/gecmis-siparisler/islem-detayi'
              element={<HistoryInner />}
              exact
            />
            <Route
              path='/arkadasina-oner'
              element={<FriendSuggestion />}
              exact
            />
            <Route path='/yardim' element={<Help />} exact />
            <Route path='/yardim/:id' element={<HelpInner />} exact />
            <Route path='/cart' element={<Cart />} exact />
            <Route path='/yakindaki-marketler' element={<Map />} exact />

            <Route path='/hediye-paylas/:token' element={<ShareGift />} exact />
            <Route path='/sayfa-bulunamadi' element={<NotFoundPage />} exact />
            <Route path='*' element={<NotFoundPage />} />
          </>
        ) : (
          <>
            <Route path='*' element={<Navigate to={'/'} />} />
          </>
        )}
        {/* <Route path='/sayfa-bulunamadi' element={<NotFoundPage />} exact />
        <Route path='*' element={<NotFoundPage />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

function ShareGift() {
  const dispatch = useDispatch()
  const naviate = useNavigate()
  const user = useSelector((state) => state.user)
  const {token} = useParams()
  useEffect(() => {
    if (!user) {
      window.routeAfterLogin = window.location.pathname
      setTimeout(() => {
        naviate('/giris-yap')
      }, 0)
    } else {
      sendRequest
        .post('electra/api/promo/PromotionSeasonOpeningTakeGift', {
          CustomerID: user.CustomerID,
          InviteCode: token,
        })
        .then((data) => {
          naviate('/biriktirdiklerim')
          dispatch(
            setModal({
              type: 'win',
              data: data[0],
              navigate: naviate,
            })
          )
        })
        .catch((err) => {
          console.log('error', err)
          naviate('/')
          handleError(err, dispatch)
        })
    }
  }, []) //eslint-disable-line

  return <LoadingAnimation />
}
