import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PinInput from 'react-pin-input'
import classcat from 'classcat'

import Button from '../Button'

import {setDefaultModal, setLoading} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {setWinStatus} from 'src/actions/yazsicak'

import c from './modal.module.css'

import smsIcon from 'src/assets/images/sms.png'

function DefaultModal() {
  const dispatch = useDispatch()
  const modalData = useSelector((selector) => selector.defaultModal)
  const [code, setCode] = useState('')
  const warn = false
  const [resendAttempt, setResendAttempt] = useState(false)
  const [message, setMessage] = useState(false)
  const modalRef = useRef()
  useEffect(() => {
    if (modalData && modalRef.current) {
      modalRef.current.style.opacity = 1
    }
  }, [modalData]) //eslint-disable-line

  if (!modalData) return false

  const closeModal = () => {
    if (modalData.onClose) {
      modalData.onClose()
    }
    dispatch(setDefaultModal(false))
  }

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true))
      const data = await sendRequest.post(
        'electra/api/promo/CheckPinCodeSicakGunler',
        {
          Pincode: code,
          Msisdn: modalData.Msisdn,
        }
      )
      let response = data[0]
      if (data[0].ResponseCode === 0) {
        dispatch(setWinStatus(data[0]))
        window.logEvent({
          event: 'migros_promo',
        })
        closeModal()
      } else {
        setMessage(response.Message)
      }
    } catch (e) {
      console.log(e)
      window.logEvent({
        event: 'migros_promo',
        error_detail: e,
      })
    } finally {
      dispatch(setLoading(false))
    }
  }

  const reSendPinCode = async () => {
    try {
      const token = await window.getCapchaToken()
      dispatch(setLoading(true))
      let res = await sendRequest.post(
        'electra/api/promo/SaveSicakGunlerTransaction',
        {
          Msisdn: modalData.Msisdn,
          KVKKPermission: modalData.KVKKPermission,
          CommPermission: modalData.CommPermission,
          CampaignPermission: 1,
        },
        {
          CaptchaToken: token,
        }
      )
      if (res.ResponseCode === 0) {
        setResendAttempt(true)
      }
      setMessage(res.Message)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  if (!modalData.mode)
    return (
      <div
        ref={modalRef}
        className={c.container}
        data-modal_container={true}
        onClick={(e) => {
          if (e.target instanceof Element && e.target.dataset.modal_container) {
            closeModal()
          }
          if (modalData.closeOnClickOutSide) {
            closeModal()
          }
        }}
      >
        <div className={classcat([modalData.themeMagnum ? c.popupMagnum : c.popup, modalData.image && c.imageMode])}>
          {!modalData.hideCloseButton && (
            <div
              onClick={closeModal}
              className={c.close}
              dangerouslySetInnerHTML={{__html: '&times'}}
            />
          )}
          {modalData.icon && (
            <img
              className={classcat([
                c.icon,
                modalData.iconSize === 'large' && c.largeIcon,
              ])}
              src={modalData.icon}
              alt='modal data icon'
            />
          )}
          {modalData.image && (
            <div className={c.imageContainer}>
              <div className={c.imageBg} />
              <img
                src={modalData.image}
                className={c.image}
                alt='modalDataImage'
              />
            </div>
          )}
          {modalData.title && (
            <div style={{color: modalData.themeMagnum && 'white'}} className={c.title} id='default-modal-title'>
              {modalData.title}
            </div>
          )}

          {modalData.message && (
            <div
              className={c.message}
              dangerouslySetInnerHTML={{__html: modalData.message}}
              style={{fontWeight: modalData.fontWeight || 'normal' ,color: modalData.themeMagnum && 'white'}}
            />
          )}

          {modalData.button && (
            <Button
              text={modalData.button}
              className={modalData.themeMagnum ? c.magnumButton : c.button}
              onClick={() => {
                if (modalData.onClick) {
                  modalData.onClick()
                }
                if (!modalData.preventCloseOnClick) {
                  closeModal()
                }
              }}
            />
          )}

          {modalData.goBackAlgida && (
            <Button 
              text={'ALGİDA İLE KAZAN’A GEÇ'} 
              className={c.goBackAlgida} 
              onClick={() => {
                closeModal();
                modalData.goBackAlgida();
              }} />
          )}

          {modalData.cancel && (
            <Button
              text={modalData.cancel || 'Vazgeç'}
              textButton
              style={{color: modalData.themeMagnum && '#F3D773' }}
              className={modalData.themeMagnum ? c.cancelMagnum : c.cancel}
              onClick={() => {
                if (modalData.onCancel) {
                  modalData.onCancel()
                }
                closeModal()
              }}
            />
          )}
        </div>
      </div>
    )

  if (modalData.mode === 'otp') {
    return (
      <>
        <div
          ref={modalRef}
          className={c.container}
          data-modal_container={true}
          onClick={(e) => {
            if (
              e.target instanceof Element &&
              e.target.dataset.modal_container
            ) {
              closeModal()
            }
            if (modalData.closeOnClickOutSide) {
              closeModal()
            }
          }}
        >
          <div className={classcat([c.popup])}>
            {!modalData.hideCloseButton && (
              <div
                onClick={closeModal}
                className={classcat([c.close, c.clickable])}
                dangerouslySetInnerHTML={{__html: '&times'}}
              />
            )}
            <img
              className={classcat([c.img, 'noselect'])}
              src={smsIcon}
              alt='pincode-logo'
            />
            <p className={c.otpTitle}>
              Telefon numaranı doğrulamak için gönderdiğimiz kodu aşağıdaki
              alana gir.
            </p>

            <PinInput
              length={4}
              secret={false}
              initialValue={code}
              onChange={(value) => setCode(value)}
              type='numeric'
              inputMode='number'
              style={{padding: '10px'}}
              inputStyle={{
                border: '1px solid #C3C3C3',
                borderRadius: '8px',
                fontSize: 16,
                fontFamily: 'OpenSansSemiBold',
              }}
              inputFocusStyle={{borderColor: 'red'}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />

            {code.length === 4 ? (
              <div
                className={classcat([c.submitButton, c.clickable, 'noselect'])}
                onClick={handleSubmit}
              >
                <p className={c.button__text}>Çekilişe Katıl</p>
              </div>
            ) : (
              <div
                className={classcat([
                  c.submitButton,
                  c.submitButtonDisabled,
                  'noselect',
                ])}
                onClick={handleSubmit}
              >
                <p className={c.button__text}>Çekilişe Katıl</p>
              </div>
            )}
            {warn && <p className={c.warningText}>{warn}</p>}
            {message && <p className={c.successText}>{message}</p>}
            {!resendAttempt && (
              <div style={{display: 'flex'}}>
                <p className={c.codeText}>Kodun ulaşmadı mı?</p>
                <p
                  className={classcat([c.resend, c.clickable])}
                  onClick={reSendPinCode}
                >
                  Tekrar Gönder
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
  if (modalData.mode === 'msg') {
    return (
      <div
        ref={modalRef}
        className={c.container}
        data-modal_container={true}
        onClick={(e) => {
          if (e.target instanceof Element && e.target.dataset.modal_container)
            closeModal()

          if (modalData.closeOnClickOutSide) closeModal()
        }}
      >
        <div className={classcat([c.popup])}>
          {!modalData.hideCloseButton && (
            <div
              onClick={closeModal}
              className={classcat([c.close, c.clickable])}
              dangerouslySetInnerHTML={{__html: '&times'}}
            />
          )}
          <div className={c.scrollText}>
            <p>
              Bu Kampanya Unilever Sanayi ve Ticaret Türk A.Ş tarafından
              16.08.2022 (Saat:00.01)-22.08.2022 (Saat:23.59) tarihleri arasında
              düzenlenmektedir. Bu tarih ve saat diliminin dışında katılımlar
              geçersiz sayılacaktır. Kampanya tarihleri arasınde Türkiye
              genelinde{' '}
              <a
                className={c.clickable}
                href='www.algidailekazan.com/yazsicaklari'
                target='_blank'
              >
                www.algidailekazan.com/yazsicaklari
              </a>{' '}
              Web sitesine giriş yaparak ilgili alana cep telefonu bilgisini
              yazıp doğrulama için giriş yaptığı cep telefonu numarasına gelecek
              kısa mesajdaki şifreyi ilgili alana girip doğrulama işlemini
              tamamlayan herkes Migros satış noktalarında geçerli 150TL’lik
              Algida ürün alışverinde geçerli %25 indirim kuponu kazanacaktır.
              %25 indirim kuponu minimum 150 TL’lik Türkiye genelindeki tüm
              Migros, 5M Migros, Migrosjet Mağazaları’ndan yapılacak Algida ürün
              alışverişlerinde geçerlidir. Kampanyaya katılım cep telefonu
              numarası başına 1 ile sınırlıdır. Kazananlar ikramiyelerini nakit
              karşılığı olarak talep edemez, haklarını devredemez ve satamaz. Bu
              kampanya diğer kampanyalarla birleştirilemez, değişiklik talep
              edilemez. Bu kampanyanın kullanımından sonra geçerli diğer
              kampanyalar kullanılabilir. Katılım formuna girilen bilgilerin
              doğruluğu katılımcının sorumluluğundadır. Bilgilerin hatalı olması
              nedeniyle hediyenin ulaştırılamaması durumunda sorumluluk
              katılımcıya aittir. 18 yaşından küçükler kampanyaya katılamaz.
              Gerektiği takdirde katılımcıdan, kendisinin 18 yaşından büyük
              olduğunu gösteren belge sunması istenebilir. Bu sınırlamalara
              dahil olan veya istenen belgeleri temin etmeyen katılımcılar
              kazanmış olsalar dahi kampanya içerisinde yer almaz ve katılımcı
              hakkını kaybeder. Kampanyaya yalnızca Türkiye Cumhuriyeti
              sınırları içerisinde ikamet eden kişiler katılabilirler. Unilever
              ilgili kodun kendisinden kaynaklı olmayan nedenlerle tahrif
              edilmeleri veyahut sistem tarafından kabul edilmemesi durumları
              dahil olmak üzere, sürece ilişkin teknik veya başka türlü
              aksaklıklar sebebiyle sorumlu tutulamaz. Kampanyaya katılım için
              internet erişim maliyeti tüketiciye aittir. Katılımcılardan,
              internet altyapısından kaynaklanan nedenlerle katılımın
              gerçekleşememesi, geç gerçekleşmesi eksik ve/veya hatalı katılım
              yapılması durumunda Unilever bu durumdan sorumlu tutulamaz.
              Promosyon kurallarının tamamını Unilever Danışma hattından (444 25
              19) ve Algida ile Kazan websitesinden öğrenebilirsiniz.
              Promosyondan yararlanmak isteyen herkes, bu kuralları kabul etmiş
              sayılır. Unilever kampanya süresince kampanya koşullarını
              değiştirme hakkını ve gerekli görülmesi durumunda promosyonu
              herhangi bir neden gösterilmeksizin durdurma veya sona erdirme
              hakkını saklı tutar. Kampanyaya ilişkin değişiklikler Algida ile
              Kazan uygulaması üzerinden duyurulacaktır
            </p>
          </div>
        </div>
      </div>
    )
  }
  if (modalData.mode === 'msg2') {
    return (
      <div
        ref={modalRef}
        className={c.container}
        data-modal_container={true}
        onClick={(e) => {
          if (e.target instanceof Element && e.target.dataset.modal_container)
            closeModal()

          if (modalData.closeOnClickOutSide) closeModal()
        }}
      >
        <div className={classcat([c.popup])}>
          {!modalData.hideCloseButton && (
            <div
              onClick={closeModal}
              className={classcat([c.close, c.clickable])}
              dangerouslySetInnerHTML={{__html: '&times'}}
            />
          )}
          <div className={c.scrollText}>
            <h5 style={{textAlign: 'center'}}>
              ALGİDA İLE SERİNLE KAMPANYASI KİŞİSEL VERİLERİN İŞLENMESİNE DAİR
              AYDINLATMA METNİ
            </h5>
            <p>
              Unilever Sanayi ve Ticaret Türk A.Ş. olarak ( "Unilever" veya
              "Şirket") olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu
              ("KVKK") uyarınca, veri sorumlusu sıfatıyla, işleme amacı ile
              bağlantılı, sınırlı ve ölçülü olacak şekilde talep ettiğimiz ve
              bizimle paylaşmış olduğunuz telefon numaranızın da aralarında
              bulunabileceği kişisel verilerinizin, algidailekazan.com
              websitesine erişim hakkınızın tesis edilmesi ve şirketimizin
              hukuki yükümlülüklerini yerine getirebilmesine ilişkin hukuki
              sebep ve amaç çerçevesinde; kaydedilecek, depolanacak, muhafaza
              edilecek, yeniden düzenlenecek, hukuken bu kişisel verileri talep
              etmeye yetkili olan kurumlar ile paylaşılacak, KVKK’nın ve diğer
              kanunların öngördüğü koşullarda yurt içinde mukim üçüncü kişilere
              aktarılabilecek, devredilebilecek, sınıflandırılabilecek ve
              KVKK’da sayılan diğer şekillerde işlenebilecek olduğunu
              bildiririz. “Algida ile Serinle” kampanyasına katılımınız
              dolayısıyla, işbu Aydınlatma Metni’ne uygun olarak paylaşmış
              olduğunuz kişisel verileriniz kampanya katılım sözleşmesinden
              doğan yükümlülüklerimizin ifası ve kanuni yükümlülüklerimiz
              kapsamında ve bu amaçlarla kullanılarak kampanya kapsamında hizmet
              sağlayan üçüncü kişi ve kurumlar ile aynı zamanda Kaizen Tasarım
              ve Teknoloji A.Ş ve D-engage Limited ile paylaşılacak, bu
              amaçlarla sınırlı olarak işlenecek ve yasal yükümlülüklerimizin
              gerektidiği yasal sürelerin hitamı ile ilk periyodik imha
              döneminde imha edilecektir. Kişisel verileriniz yukarıda
              belirtilen amaçlarla paylaşılabilecektir ve hizmet aldığımız
              kişilerin yurtiçindeki güvenli sunucularında ilgili yasal
              düzenlemelere, KVKK hükümlerine ve Unilever standartlarına uygun
              olarak saklanacaktır. Bu kapsamda Unilever, kişisel verilerinizin
              güvenliğini sağlamak adına yasal mevzuat ile belirlenen gerekli
              teknik ve idari güvenlik önlemlerini almaktadır.
              <br></br>
              <br></br>
              KVKK’nın 11. maddesi uyarınca başvurarak, kişisel verilerinizin;
              <br></br>
              <ul>
                <li>a) işlenip işlenmediğini öğrenme, </li>
                <li>b) işlenmişse bilgi talep etme, </li>
                <li>
                  c) işlenme amacını ve amacına uygun kullanılıp
                  kullanılmadığını öğrenme,{' '}
                </li>
                <li>
                  d) yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,{' '}
                </li>
                <li>e) eksik / yanlış işlenmişse düzeltilmesini isteme, </li>
                <li>
                  f) KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde
                  silinmesini / yok edilmesini isteme,{' '}
                </li>
                <li>
                  g) aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f)
                  bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,{' '}
                </li>
                <li>
                  h) münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
                  aleyhinize bir sonucun ortaya çıkmasına itiraz etme,{' '}
                </li>
                <li>
                  i) hukuka aykırı olarak işlenmesi sebebiyle zarara uğramanız
                  hâlinde zararın giderilmesini talep etme hakkına sahipsiniz.
                </li>
              </ul>
              Kişisel verilerinizin Unilever tarafından veri sorumlusu sıfatı
              ile işlendiği ölçüde KVKK’nın 11. maddesinde yer alan haklarınızı{' '}
              <a
                rel='noreferrer'
                className={c.clickable}
                href='https://www.unilever.com.tr/contact/contact-form'
                target='_blank'
              >
                https://www.unilever.com.tr/contact/contact-form
              </a>{' '}
              adresindeki iletişim formu aracılığıyla veya Unilever tüketici
              hattı 444 25 19’u arayarak kullanabilirsiniz. Başvurunuzda yer
              alan talepleriniz, talebin niteliğine göre en kısa sürede ve en
              geç otuz gün içinde sonuçlandırılacaktır. Genel KVKK bilgilendirme
              metni için tıklayınız.{' '}
              <a
                rel='noreferrer'
                className={c.clickable}
                href='https://www.unilever.com.tr/kisisel-verilerin-korunmasi/'
                target='_blank'
              >
                https://www.unilever.com.tr/kisisel-verilerin-korunmasi/
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (modalData.mode === 'unilever') {
    return (
      <div
        ref={modalRef}
        className={c.container}
        data-modal_container={true}
        onClick={(e) => {
          if (e.target instanceof Element && e.target.dataset.modal_container)
            closeModal()

          if (modalData.closeOnClickOutSide) closeModal()
        }}
      >
        <div className={classcat([c.popup])}>
          {!modalData.hideCloseButton && (
            <div
              onClick={closeModal}
              className={classcat([c.close, c.clickable])}
              dangerouslySetInnerHTML={{__html: '&times'}}
            />
          )}
          <div className={c.scrollText}>
            <ul style={{padding: '30px 0 15px 0'}}>
              <p className={c.listTitle}>Gıda ve Dondurma:</p>
              <li></li>
              <li>Algida</li>
              <li></li>
              <li>Calve</li>
              <li></li>
              <li>Carte d'Or</li>
              <li></li>
              <li>Cornetto</li>
              <li></li>
              <li>Hellmanns</li>
              <li></li>
              <li>Knorr</li>
              <li></li>
              <li>Lipton</li>
              <li></li>
              <li>Magnum</li>
              <li></li>
              <li>Max</li>
              <li></li>
              <p className={c.listTitle}>Ev Bakımı:</p>
              <li></li>
              <li>Cif</li>
              <li></li>
              <li>Domestos</li>
              <li></li>
              <li>OMO</li>
              <li></li>
              <li>Puffy Love</li>
              <li></li>
              <li>Rinso</li>
              <li></li>
              <li>Vim</li>
              <li></li>
              <li>Yumoş</li>
              <li></li>
              <p className={c.listTitle}>Güzellik ve Kişisel Bakım:</p>
              <li></li>
              <li>Axe</li>
              <li></li>
              <li>Clear</li>
              <li></li>
              <li>Dove</li>
              <li></li>
              <li>Dove Men+Care</li>
              <li></li>
              <li>Elidor</li>
              <li></li>
              <li>Love Beauty & Planet</li>
              <li></li>
              <li>Pure Line</li>
              <li></li>
              <li>Rexona</li>
              <li></li>
              <li>Signal</li>
              <li></li>
              <li>Simple</li>
              <li></li>
              <li>Toni&Guy</li>
              <li></li>
              <li>Vaseline</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
  ////
}

export default DefaultModal
