import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Checkbox from 'src/components/Checkbox'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import PhoneInput from 'src/components/PhoneInput'
import Select from 'src/components/Select'
import LoadingAnimation from 'src/components/LoadingAnimation'
import ModalTemplate from 'src/components/ModalTemplate'

import {setDefaultModal, setErrorModal, setLoading} from 'src/actions/element'
import {handleError} from 'src/helpers/errorHandler'
import sendRequest from 'src/helpers/service'
import {setCityList} from 'src/actions/data'

import c from './magnumform.module.css'

import raffleSuccessIcon from 'src/assets/images/magnum/raffleMagnumSuccess.png'
import raffleFailIcon from 'src/assets/images/magnum/raffleMagnumError.png'
import raffleWarningIcon from 'src/assets/images/magnum/raffleMagnumWarning.png'
import headerArrowIcon from 'src/assets/images/header/arrow.png'
import formMagnumBack from 'src/assets/images/magnum/magnumFormBack.png'
import coinIcon from 'src/assets/images/coin.png'

import magnumBack from 'src/assets/images/magnum/magnumCodeBack2.png'

export default function MagnumForm({onClose, data, navigate = () => {}}) {
  const dispatch = useDispatch()

  const modalRef = useRef()
  const contentRef = useRef()

  const headers = useSelector((state) => state.headers) || window.headers

  const user = useSelector((state) => state.user)
  const cityList = useSelector((state) => state.data.cityList)
  const [cityOptions, setCityOptions] = useState(false)
  const [textModal, setTextModal] = useState(false)

  const [values, setValues] = useState({})
  const [attempted, setAttempted] = useState(false)

  const [formData, setFormData] = useState(false)
  const [formDataEditted, setFormDataEditted] = useState(false)
  const [x, setX] = useState(0) //eslint-disable-line

  const showClarificationModal = (event) => {
    event.preventDefault()
    event.stopPropagation()
    sendRequest
      .post('electra/api/promo/raffleTermsAndConditions', {
        CustomerID: user.CustomerID,
        BrandCode: data.brandCode,
      })
      .then((data) => {
        const html = data[0].AydinlatmaMetni
        if (html) {
          setTextModal(html)
        }
      })
      .catch((err) => {
        dispatch(
          setErrorModal({
            message: err.response.Message || headers.raffleTermsErrorMessage,
          })
        )
        // handleError(err, dispatch)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    if (formData && !formDataEditted) {
      let tmp = [...formData]
      tmp[5].label = `"Kampanya <a data-id='newsletter' data-brand_code='002' onclick="window.onCheckboxLinkPress(event)">katılım koşullarını</a> kabul ediyorum.<br><br> <a style="color: red; text-decoration: underline;" onclick="window.onClarification(event)">Aydınlatma Metni</a>"`
      setFormDataEditted(true)
      setFormData(tmp)
    }
  }, [formData]) //eslint-disable-line

  useEffect(() => {
    let underlineFlag = false

    window.onCheckboxLinkPress = openHTMLPage
    window.onClarification = showClarificationModal

    setFormData(
      data.raffleForm &&
        data.raffleForm.map((row) => {
          if (row.type === 'checkbox') {
            if (row.label.includes('#_#')) {
              let newLabel = ''
              const parts = row.label.split(/(#_#)/g)

              parts.forEach((part) => {
                if (part === '#_#') {
                  underlineFlag = !underlineFlag
                  return
                }

                if (underlineFlag) {
                  newLabel += `<a data-id='${row.id}' data-brand_code='${row.brandCode}' onclick="window.onCheckboxLinkPress(event)">${part}</a>`
                } else {
                  newLabel += part
                }
              })
              row.label = newLabel
            }
          }
          return row
        })
    )

    if (!cityList) {
      sendRequest.post('electra/api/promo/getcitydata').then((data) => {
        dispatch(setCityList(data.cities))
        setCityOptions(mapCityData(data.cities))
      })
    } else {
      setCityOptions(mapCityData(cityList))
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (formData) buildStateStructure()
  }, [formData]) //eslint-disable-line

  const navigateToWallet = () => {
    navigate('/magnum')
    onClose()
  }

  const handleSubmit = () => {
    window.logEvent({
      event: 'magnum_cekilis_button',
      UserID: user.CustomerID,
      button_name: 'Çekilişe Katıl',
    })

    setAttempted(true)

    if (!checkFormValidated(true)) return

    dispatch(setLoading(true))

    sendRequest
      .post(
        'electra/api/promo/participateRaffle',
        {
          CustomerID: user.CustomerID,
          ProcessID: data.ProcessID,
          StickCode: data.code,
          Input: JSON.stringify([{deviceType: 'web', ...values}]),
          RaffleID: data?.raffleID,
        },
        {},
        true
      )
      .then((res) => {
        const data = res[0]
        if (data.ResponseCode === 0) {
          window.logEvent({
            event: 'buttonClick',
            Category: 'Button',
            Action: 'click',
            Label: 'Çekilşe Katıl',
            UserID: user.CustomerID,
          })

          dispatch(
            setDefaultModal({
              icon: raffleSuccessIcon,
              iconSize: 'large',
              themeMagnum: true,
              title: 'Çekilişe Katılım Başarılı!',
              message: data.Message || 'Çekilişe katılmaya hak kazandınız',
              button: 'TAMAM',
              onClose: navigateToWallet,
              onClick: () => {
                window.logEvent({
                  event: 'magnum_cekilis_button',
                  UserID: user.CustomerID,
                  button_name: 'Tamam',
                })
              },
            })
          )
        } else {
          if (!data.Message) {
            handleError(false, dispatch)
          } else {
            dispatch(
              setDefaultModal({
                icon: raffleWarningIcon,
                iconSize: 'large',
                title: 'Çekilişe Katıl',
                button: 'TAMAM',
                themeMagnum: true,
                message: data.Message || headers.joinRaffleErrorMessage,
                onClose: navigateToWallet,
              })
            )
          }
        }
      })
      .catch((err) => {
        dispatch(
          setDefaultModal({
            title: 'Çekilişe Katılım Başarısız',
            icon: raffleFailIcon,
            iconSize: 'large',
            message:
              headers.joinRaffleErrorMessage ||
              'Çekilişe katılım şu an gerçekleştirilemiyor. Lütfen daha sonra tekrar deneyiniz.',
            button: 'KAPAT',
            themeMagnum: true,
            onClose: navigateToWallet,
          })
        )
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const mapCityData = (data) => {
    return data.map((r) => {
      return {
        label: r.city,
        value: r.cityId,
      }
    })
  }

  const openHTMLPage = (event) => {
    dispatch(setLoading(true))
    event.preventDefault()
    event.stopPropagation()
    sendRequest
      .post('electra/api/promo/raffleTermsAndConditions', {
        CustomerID: user.CustomerID,
        BrandCode: data.brandCode,
      })
      .then((data) => {
        const html =
          event.target.dataset.id === 'newsletter'
            ? data[0].RaffleTermsAndConditions
            : data[0].RaffleKVKK
        if (html) {
          setTextModal(html)
        }
      })
      .catch((err) => {
        dispatch(
          setErrorModal({
            message: err.response.Message || headers.raffleTermsErrorMessage,
          })
        )
        // handleError(err, dispatch)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  // //This method return true if field is not valid - VALUE IS REVERSED
  const checkValidated = (inputData) => {
    if (!inputData.required || !inputData.visible) return false

    let returnVal = true
    let value = values[inputData.id] || ''
    if (typeof value == 'string') value = value.trim()

    switch (inputData.type) {
      case 'checkbox':
        returnVal = !value
        break
      case 'phone':
        returnVal = value.length < 10
        break
      case 'mail':
        const regexp = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) //eslint-disable-line
        returnVal = !regexp.test(value)
        break
      case 'citydropdown':
      case 'dropdown':
        returnVal = value == 0 //eslint-disable-line
        break

      default: //Default represents normal text inputs.
        //İf there is minLength
        if (inputData.minLength && inputData.minLength !== 0)
          returnVal = value.length < inputData.minLength
        //IF there is no minlength we will just check for is it empty
        else returnVal = value.length === 0
        break
    }

    return returnVal
  }

  // //false döner ise
  const checkFormValidated = (isScrollEnabled) => {
    let returnVal = true
    for (let index = 0; index < formData.length; index++) {
      const inputData = formData[index]
      returnVal = !checkValidated(inputData)

      //Eğer validate edilmemiş ise o inputa focuslanıp, döngüyü bitirecek
      if (!returnVal) {
        if (isScrollEnabled) scrollTo(inputData.id)

        break
      }
    }
    return returnVal
  }

  const scrollTo = (inputIndex) => {
    const notValidInput = document.getElementById('form-input-' + inputIndex)
    if (!notValidInput) {
      if (window.isMobile()) contentRef.current.scrollTop = 0
      else modalRef.current.scrollTop = 0
    } else {
      const rect = notValidInput.getBoundingClientRect()
      if (window.isMobile()) contentRef.current.scrollTop = rect.top + 50
      else modalRef.current.scrollTop = rect.top + 50
    }
  }

  const buildStateStructure = () => {
    const stateObj = {}
    formData.forEach((input) => {
      if (input.id === 'birthdate' && input.value === '1900-01-01')
        stateObj[input.id] = ''
      else if (input.type === 'checkbox') {
        stateObj[input.id] = input.value
      } else if (input.type === 'citydropdown') {
        stateObj[input.id] = isNaN(input.value) ? 0 : input.value
      } else stateObj[input.id] = input.value
    })
    setValues(stateObj)
  }

  const handleOnChange = (inputData, val) => {
    if (typeof val === 'string') {
      val = val.replace(/   /g, '  ') //eslint-disable-line
      if (inputData.restrictNumbers === true) val = val.replace(/[0-9]/g, '')
    }

    setValues((prev) => {
      prev[inputData.id] = val
      return prev
    })
    setX((x) => x + 1)
  }

  const showWarningModal = () => {
    dispatch(
      setDefaultModal({
        icon: raffleWarningIcon,
        iconSize: 'large',
        title: 'Dikkat!',
        message:
          'Çekilişe katılmadan sayfadan ayrılıyorsun! Hemen çekilişe katıl, efsanevi Magnum hediyelerinden birini kazanma şansını yakala!',
        button: 'FORMA DÖN',
        cancel: 'Kodunu Sakla ve Sonra Katıl',
        themeMagnum: true,
        onCancel: () => {
          navigate('/magnum')
          onClose()
        },
      })
    )
  }

  if (!formData || !cityOptions) return <LoadingAnimation />

  return (
    <ModalTemplate
      containerClass={c.modal}
      containerRef={modalRef}
      contentClass={c.content}
      contentRef={contentRef}
      closeClass={c.close}
      onClose={showWarningModal}
      dontClosable={true}
    >
      <div className={c.header}>
        <div className={c.back} onClick={showWarningModal}>
          <img src={headerArrowIcon} className={c.arrow} alt='geri' />
        </div>
        {/* <span>ÇEKİLİŞE KATIL</span> */}
      </div>

      <img src={magnumBack} className={c.headerBackImage} />
      <div className={c.headerTitleCard}>
        <b className={c.headerTitle}>ÇEKİLİŞE KATIL</b>
      </div>

      <div className={c.top}>
        {data?.code && (
          <div className={c.left}>
            <div className={c.enteredCode}>{data.code}</div>
            <div style={{color: 'white'}}>Girilen Kod</div>
          </div>
        )}

        {data?.code && (
          <div className={c.right}>
            <div className={c.points}>
              <img src={coinIcon} alt='coin' />
              {data.Point || '0'} PUAN
            </div>
            <div style={{color: 'white'}}>kazandınız</div>
          </div>
        )}
      </div>

      <img className={c.raffleImage} src={data.raffleImageUrl} alt='raffle' />

      <div className={c.directiveMessage}>
        Çekilişe katılmak için aşağıdaki bilgileri doldurunuz.
      </div>

      {formData.map((row) => {
        if (!row.visible) return false

        switch (row.type) {
          case 'input':
          case 'textarea':
          case 'mail':
          case 'date':
            let type = 'text'
            if (row.type === 'mail' || 'date') type = row.type

            return (
              <Input
                style={{color: 'white'}}
                id={'form-input-' + row.id}
                key={row.id}
                className={c.input}
                value={values[row.id] || ''}
                label={row.label}
                onChange={(val) => handleOnChange(row, val)}
                type={type}
                warn={attempted && checkValidated(row)}
                warning='Bu alanın girilmesi zorunludur.'
                isMagnum={true}
              />
            )
          case 'phone':
            return (
              <div
                id={'form-input-' + row.id}
                className={c.phoneField}
                key={row.id}
              >
                <label className={c.label}>{row.label}</label>
                <PhoneInput
                  isMagnum={true}
                  className={c.phoneInput}
                  value={values[row.id] || ''}
                  onChange={(val) =>
                    handleOnChange(row, val.replace(/\s+/g, ''))
                  }
                />
              </div>
            )
          case 'checkbox':
            return (
              <FormCheckbox
                id={'form-input-' + row.id}
                key={row.id}
                label={row.label}
                value={values[row.id]}
                onChange={(val) => handleOnChange(row, val)}
                warn={attempted && checkValidated(row)}
              />
            )
          case 'dropdown':
          case 'citydropdown':
            return (
              <Select
                id={'form-input-' + row.id}
                key={row.id}
                className={c.select}
                label={row.label}
                value={values[row.id]}
                onChange={(val) => handleOnChange(row, val)}
                options={cityOptions}
                defaultOption={{label: 'Şehir seçin', value: 0}}
                isMagnum={true}
              />
            )
          default:
            return false
        }
      })}

      {textModal && (
        <TextModal html={textModal} onClose={() => setTextModal(false)} />
      )}

      <Button
        className={c.submitButton}
        text='ÇEKİLİŞE KATIL'
        onClick={handleSubmit}
      />
    </ModalTemplate>
  )
}

function TextModal({html, onClose}) {
  return (
    <ModalTemplate
      containerClass={c.textModal}
      contentClass={c.textModalContent}
      onClose={onClose}
    >
      <div className={c.html} dangerouslySetInnerHTML={{__html: html}} />
    </ModalTemplate>
  )
}

function FormCheckbox({id, label, value, onChange, warn}) {
  return (
    <Checkbox
      id={id}
      className={c.checkbox}
      label={label}
      value={value}
      onChange={onChange}
      warn={warn}
    />
  )
}
