import React from 'react'
import classcat from 'classcat'
import {useDispatch, useSelector} from 'react-redux'

import Button from 'src/components/Button'

import {setDefaultModal, setModal} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {setGifts} from 'src/actions/data'
import {handleError} from 'src/helpers/errorHandler'

import c from './classics.module.css'

import warningIcon from 'src/assets/images/raffle/warning.png'
import giftDetailTopIcon from 'src/assets/images/gift-detail/top.png'

export default function Classics({onClose, data, navigate}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const isTherePoint = data.Point && data.Point !== 0

  const handleViewSurprise = () => {
    if (!data.GiftTypeID && isTherePoint) {
      window.logEvent({
        event: 'redeemPoint',
        UserID: user.CustomerID,
        promo_name: data.promoName,
        PagePath: data.page,
      })
      navigate('/oduller')
    } else {
      navigate('/biriktirdiklerim')
    }
    onClose()
  }

  const showWarningModal = () => {
    dispatch(
      setDefaultModal({
        icon: warningIcon,
        iconSize: 'large',
        title: 'Dikkat!',
        message: 'Çekilişe katılmadan çıkmak istediğine emin misin? Çekilişe katılarak büyük hediyelerden birini kazanma şansını kaçırma!',
        button: 'ÇEKİLİŞE KATIL',
        cancel: 'Kodunu Sakla ve Sonra Katıl',
        onCancel: () => {
          navigate('/biriktirdiklerim')
          onClose()
        },
      })
    )
  }
  const submitPrize = async (giftID) => {
    try {
      let response = await sendRequest.post(
        'electra/api/promo/PromotionClassicsTakeGift',
        {
          customerID: user.CustomerID,
          GiftID: giftID,
        }
      )
      dispatch(
        setDefaultModal({
          message: response[0]?.Message,
          button: 'TAMAM',
          onClose: () => {
            dispatch(setGifts(false))
            dispatch(setDefaultModal(false))
            navigate('/biriktirdiklerim')
          },
        })
      )
      onClose()
    } catch (error) {
      handleError(error, dispatch)
      console.log(error)
    }
  }
  const chooseModal = async (item) => {
    setTimeout(() => {
      dispatch(
        setDefaultModal({
          message: `${item?.Title} seçmek istediğinizden emin misiniz? `,
          button: 'Seç',
          onClick: submitPrize.bind(this, item.GiftID),
        })
      )
    }, 100)
  }

  const openRaffleForm = () => {
    dispatch(
      setModal({
        type: 'raffle',
        data,
        navigate,
      })
    )
  }

  return (
    <div className={classcat([c.modal, data.raffleAvailable && c.raffleModal])}>
      <div className={c.modalContent}>
        <div className={c.content}>
          <img
            src={giftDetailTopIcon}
            className={c.coverImage}
            alt='giftDetailTopIcon'
          />

          <div className={c.surprise}>{data?.ClassicsPromo?.Message}</div>

          <div className={classcat([c.giftsField])}>
            {data?.ClassicsPromo?.Gifts.map((item) => {
              return (
                <div className={c.giftContainer}>
                  <img
                    src={item?.ImageUrl}
                    className={c.giftImage}
                    alt='giftImage'
                  />
                  <p>{item?.Title}</p>
                  <div
                    className={c.selectGift}
                    onClick={() => {
                      chooseModal(item)
                    }}
                  >
                    <p className={c.selectGiftText}>SEÇ</p>
                  </div>
                </div>
              )
            })}
          </div>
          {!data.raffleAvailable && (
            <Button
              className={c.firstButton}
              text={data.GiftTypeID ? 'SÜRPRİZLERİME GİT' : 'PUANLARIMI HARCA'}
              onClick={handleViewSurprise}
            />
          )}
        </div>
        {data.raffleAvailable && (
          <RaffleField
            image={data.raffleImageUrl}
            onCancel={showWarningModal}
            onClick={openRaffleForm}
          />
        )}
      </div>
    </div>
  )
}

function RaffleField({image, onClick, onCancel}) {
  return (
    <div className={classcat([c.content, c.raffleContent])}>
      <img className={c.raffleImage} src={image} alt='raffle' />
      <div className={c.raffleTitle}>
        Çekilişe katılmak için hak kazandınız!
      </div>
      <Button
        className={classcat([c.button, c.raffleButton])}
        text='ÇEKİLİŞE KATIL'
        onClick={onClick}
      />
      <Button
        className={classcat([c.button, c.raffleCancelButton])}
        text='Kodunu Sakla ve Sonra Katıl'
        textButtonx
        onClick={onCancel}
      />
    </div>
  )
}
