import React from 'react'

import ModalTemplate from 'src/components/ModalTemplate'

import c from './textModal.module.css'

import giftDetailTopIcon from 'src/assets/images/gift-detail/top.png'

export default function TextModal({onClose, html = '', title}) {
  return (
    <ModalTemplate
      onClose={onClose}
      containerClass={c.modal}
      contentClass={c.content}
      closeClass={c.close}
    >
      <img
        src={giftDetailTopIcon}
        className={c.coverImage}
        alt='giftDetailtop'
      />

      <div className={c.title}>{title}</div>

      <div className={c.html} dangerouslySetInnerHTML={{__html: html}} />
    </ModalTemplate>
  )
}
