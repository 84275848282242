import error from '../assets/images/error.svg'
import warning from '../assets/images/warning.svg'

export const setHeader = (value) => {
  return {
    type: 'SET_HEADER',
    payload: value,
  }
}

export const setModal = (value) => {
  return {
    type: 'SET_MODAL',
    payload: value,
  }
}

export const setDefaultModal = (value) => {
  return {
    type: 'SET_DEFAULT_MODAL',
    payload: value,
  }
}

export const setSuccessModal = (value) => {
  return {
    type: 'SET_DEFAULT_MODAL',
    payload: {
      icon: error,
      button: 'Tamam',
      ...value,
    },
  }
}

export const setErrorModal = (value, navigate) => {
  let payload = {
    icon: error,
    button: 'TAMAM',
    ...value,
  }

  if (!value || !value.title) payload.title = ''

  if (!value || !value.message) payload.message = 'Beklenmedik bir hata oluştu.'

  if (navigate) {
    payload.cancel = 'Ana Sayfaya Dön'
    payload.onCancel = () => navigate('/')
  }

  return {
    type: 'SET_DEFAULT_MODAL',
    payload,
  }
}

export const setWarningModal = (value) => {
  return {
    type: 'SET_DEFAULT_MODAL',
    payload: {
      icon: warning,
      button: 'TAMAM',
      ...value,
    },
  }
}

export const setLoading = (value) => {
  return {
    type: 'SET_LOADING',
    payload: value,
  }
}

export const setCodeModalStatus = (value) => {
  return {
    type: 'SET_CODE_MODAL_STATUS',
    payload: value,
  }
}
