const defaultState = {
  wallet: false,
  gifts: false,
  headers: false,
  cityList: false,
  products: false,
  banners: false,
  productGroups: false,
  orderHistory: false,
}

export default function data(state = defaultState, action) {
  switch (action.type) {
    case 'SET_WALLET':
      return {
        ...state,
        wallet: action.payload,
      }
    case 'SET_GIFTS':
      return {
        ...state,
        gifts: action.payload,
      }
    case 'SET_ORDER_HISTORY':
      return {
        ...state,
        orderHistory: action.payload,
      }
    case 'SET_HEADERS':
      return {
        ...state,
        headers: action.payload,
      }
    case 'SET_CITY_LIST':
      return {
        ...state,
        cityList: action.payload,
      }
    case 'SET_HOME_DATA':
      return {
        ...state,
        products: action.payload.products,
        banners: action.payload.banners,
        productGroups: action.payload.productGroups,
      }
    case 'RESET_DATA':
      return defaultState
    default:
      return state
  }
}
