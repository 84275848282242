import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ModalTemplate from 'src/components/ModalTemplate'
import SupplierCard from './SupplierCard'
import WheelSupplierCard from './SupplierCard/WheelSupplierCard'

import {setLoading, setModal} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import c from './supplier.module.css'
import {handleError} from 'src/helpers/errorHandler'

export default function Supplier({onClose, Props}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [retailers, setRetailers] = useState()
  const [ready, setReady] = useState(false)
  const headers = useSelector((state) => state.data.headers)

  const fetchSuppliers = () => {
    if (Props?.RetailerSelectionTypeID === 2) return
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/GetPromotionRetailers', {
        CustomerID: user.CustomerID,
        PromotionID: Props.PromotionID,
      })
      .then((data) => {
        setRetailers(data)
        setReady(true)
        dispatch(setLoading(false))
      })
      .catch((error) => {
        handleError(error, dispatch)
      })
  }

  const fetchRetailers = () => {
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/getPromotion', {
        CustomerID: user.customerID,
        PromotionID: Props.PromotionID,
      })
      .then((res) => {
        setRetailers(res)
        setReady(true)
        dispatch(setLoading(false))
      })
      .catch((err) => {
        handleError(err, dispatch)
      })
  }

  useEffect(() => {
    Props?.NotSelectedRetailer ? fetchRetailers() : fetchSuppliers()
  }, []) //eslint-disable-line

  const Content = () => {
    if (Props.RetailerSelectionTypeID === 0) {
      //only gift
      dispatch(
        setModal({
          type: 'gift-list',
          PromotionID: Props.PromotionID,
          RetailerData: Props,
        })
      )
    } else if (Props.RetailerSelectionTypeID === 1) {
      //retailer then gift
      return (
        <div className={c.content}>
          <p className={c.title}>
            {headers.supplierSelectionTitle ||
              'SÜRPRİZİNİ ALACAĞIN TEDARİKÇİYİ SEÇ'}
          </p>
          <div className={c.cardsContainer}>
            {ready &&
              retailers.map((product, index) => {
                // console.log('test', product)
                return (
                  <SupplierCard
                    key={'campaign-product-' + product.RetailerID}
                    product={product}
                    PromotionID={Props.PromotionID}
                    Props={Props}
                    // onClick={() => handleProductSelection(product)}
                  />
                )
              })}
          </div>
        </div>
      )
    } else if (
      Props.RetailerSelectionTypeID === 2 &&
      !Props.selectingRetailer
    ) {
      //gift then retailer
      dispatch(
        setModal({
          type: 'gift-list',
          PromotionID: Props.PromotionID,
          RetailerData: Props,
        })
      )
    } else if (Props.RetailerSelectionTypeID === 2 && Props.selectingRetailer) {
      // console.log(Props.Retailers)
      return (
        <div className={c.content}>
          <p className={c.title}>
            {headers.supplierSelectionTitle ||
              'SÜRPRİZİNİ ALACAĞIN TEDARİKÇİYİ SEÇ'}
          </p>
          <div className={c.cardsContainer}>
            {Props.Retailers.map((product, index) => {
              // console.log('test', product)
              return (
                <SupplierCard
                  key={'campaign-product-' + product.RetailerID}
                  product={product}
                  PromotionID={Props.PromotionID}
                  Props={Props}
                  // onClick={() => handleProductSelection(product)}
                />
              )
            })}
          </div>
        </div>
      )
    } else if (Props.RetailerSelectionTypeID === 3) {
      //just retailer
      return (
        <div className={c.content}>
          <p className={c.title}>
            {headers.supplierSelectionTitle ||
              'SÜRPRİZİNİ ALACAĞIN TEDARİKÇİYİ SEÇ'}
          </p>
          <div className={c.cardsContainer}>
            {ready &&
              retailers.map((product, index) => {
                // console.log('test', product)
                return (
                  <SupplierCard
                    key={'campaign-product-' + product.RetailerID}
                    product={product}
                    PromotionID={Props.PromotionID}
                    Props={Props}
                    // onClick={() => handleProductSelection(product)}
                  />
                )
              })}
          </div>
        </div>
      )
    } else if (ready && Props?.NotSelectedRetailer) {
      return (
        <div className={c.content}>
          <p className={c.title}>
            {headers.wheelSupplierSelectionTitle ||
              'Hediye dondurmayı alacağın tedarikçiyi seç'}
          </p>
          <div className={c.cardsContainer}>
            {retailers[0].Retailers?.map((product) => {
              return (
                <WheelSupplierCard
                  StickCodeID={Props?.stickCodeId}
                  product={product}
                />
              )
            })}
          </div>
        </div>
      )
    } else if (Props.wheel) {
      return (
        <div className={c.content}>
          <p className={c.title}>
            {headers.wheelSupplierSelectionTitle ||
              'SÜRPRİZİNİ ALACAĞIN TEDARİKÇİYİ SEÇ'}
          </p>
          <div className={c.cardsContainer}>
            {Props.map((product) => {
              return (
                <WheelSupplierCard
                  StickCodeID={Props?.stickCodeId}
                  product={product}
                />
              )
            })}
          </div>
        </div>
      )
    } else {
      return false
    }
  }

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.container}
      closeClass={c.close}
      isDrawer={false}
      onClose={() => {
        onClose()
      }}
    >
      <Content />
    </ModalTemplate>
  )
}
