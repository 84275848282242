import React from 'react'
import classcat from 'classcat'

import c from './animation.module.css'

import gif from 'src/assets/images/animation.gif'

export default function Animation({inline = false, className}) {
  return (
    <div className={classcat([c.page, className, inline && c.inline])}>
      <img className={c.animation} src={gif} alt='animation' />
    </div>
  )
}
