export const setWalletState = (value) => {
  return {
    type: 'SET_WALLET',
    payload: value,
  }
}

export const setGifts = (value) => {
  return {
    type: 'SET_GIFTS',
    payload: value,
  }
}

export const setHeaders = (value) => {
  return {
    type: 'SET_HEADERS',
    payload: value,
  }
}

export const setCityList = (value) => {
  return {
    type: 'SET_CITY_LIST',
    payload: value,
  }
}

export const setHomeData = (value) => {
  return {
    type: 'SET_HOME_DATA',
    payload: value,
  }
}

export const setOrderHistory = (value) => {
  return {
    type: 'SET_ORDER_HISTORY',
    payload: value,
  }
}

export const resetData = (value) => {
  return {
    type: 'RESET_DATA',
    payload: value,
  }
}
