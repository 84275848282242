import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'src/components/Button'
import ModalTemplate from 'src/components/ModalTemplate'
import {setDefaultModal, setModal} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import headerArrowIcon from 'src/assets/images/header/arrow.png'
import {isIOS, isAndroid, isWindows, isMacOs} from 'react-device-detect'

import c from './ibbhub.module.css'

import ibbhubSuccess from 'src/assets/images/ibbhub/ibbhub-success.png'

export default function IbbHubModal({onClose, navigate, data}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const frameRef = useRef(null)

  const useIBBGift = async () => {
    try {
      await sendRequest.post('electra/api/promo/UseIBBGift', {
        CustomerID: user.CustomerID,
        giftCode: data.gift.GiftCode,
      })
      handleSuccessModal()
    } catch (err) {
      console.log(err, 'error useIBBHubGift')
      handleErrorModal('Bilinmeyen bir hata oluştu.')
    }
  }

  const handleErrorModal = (message) => {
    dispatch(setModal(false))
    dispatch(
      setDefaultModal({
        title: 'Üzgünüz!',
        message:
          message ||
          'Girmiş olduğunuz kimlik numarasına ait bir dijital İstanbulkart bulunamadı.\nİstanbulkart uygulamasını indirerek hesap oluşturduktan sonra yükleme işlemini tekrarlayabilirsin.',
        button: window.isMobile()
          ? "İSTANBULKART MOBİL'İ AÇ"
          : 'İSTANBULKART WEB SİTESİNE GİT',
        onClick: () => redirectToIBBApp(),
        cancel: 'TAMAM',
        onCancel: () => {
          onClose()
        },
      })
    )
  }

  const handleSuccessModal = () => {
    dispatch(setModal(false))
    dispatch(
      setDefaultModal({
        icon: ibbhubSuccess,
        iconSize: 'large',
        message:
          'Kazandığın dijital İstanbulkart bakiyen 24 saat içerisinde yüklenecektir. Dijital İstanbulkart’ını QR ödemelerinde kullanmak için hemen İstanbulkart Mobil’i aç!',
        button: window.isMobile()
          ? "İSTANBULKART MOBİL'İ AÇ"
          : 'İSTANBULKART WEB SİTESİNE GİT',
        onClick: () => redirectToIBBApp(),
        cancel: 'TAMAM',
        onCancel: () => {
          onClose()
        },
      })
    )
  }

  async function handleMessage(event) {
    switch (event) {
      case 'success':
        await useIBBGift()
        break
      case 'daily_limit_uuid':
        handleErrorModal(
          'Günlük yükleme limitini doldurdun. Yarın tekrar deneyerek Algida ile kazanmaya devam edebilirsin.'
        )
        break
      case 'daily_limit_tckn':
        handleErrorModal(
          'Bu TC Kimlik Numarasına ait günlük yükleme limitini doldurdun. Yarın tekrar deneyerek Algida ile kazanmaya devam edebilirsin.'
        )
        break
      case 'daily_total_limit_tckn':
        handleErrorModal(
          'Bu TC Kimlik Numarasına ait günlük yüklenebilecek TL limitini doldurdun. Yarın tekrar deneyerek Algida ile kazanmaya devam edebilirsin.'
        )
        break
      case 'daily_total_limit_uuid':
        handleErrorModal(
          'Günlük yüklenebilecek TL limitini doldurdun. Yarın tekrar deneyerek Algida ile kazanmaya devam edebilirsin.'
        )
        break
      case 'monthly_limit_tckn':
        handleErrorModal(
          'Bu TC Kimlik Numarasına ait aylık yüklenebilecek TL limitini doldurdun. Daha sonra tekrar deneyebilirsin.'
        )
        break
      case 'monthly_limit_uuid':
        handleErrorModal(
          'Aylık yüklenebilecek TL limitini doldurdun. Daha sonra tekrar deneyebilirsin.'
        )
        break
      case 'unexpected_error':
        handleErrorModal(
          headers.errorUnexpectedIBBHubMessage || 'Beklenmedik bir hata oluştu.'
        )
        break
      case 'wrong_input':
        handleErrorModal('Yanlış TCKN değeri girdiniz.')
        break
      case 'limit_error':
        handleErrorModal('Yükleme limitini doldurdun.')
        break
      case 'uknown_account':
        handleErrorModal('Hatalı deneme yaptınız.')
        break
      case 'technical_error':
        handleErrorModal('Teknik bir hata oluştu.')
        break
      case 'request_rate_limit':
        handleErrorModal(
          'Çok sayıda istek yapıldığı için işleme devam edemiyoruz. Lütfen biraz süre bekleyip tekrar deneyin.'
        )
        break
      case 'belbim_error':
        handleErrorModal(
          'Sistemde bir hata oluştu. Lütfen daha sonra tekrar deneyin.'
        )
        break
      case 'invalid_amount':
        handleErrorModal('Miktar geçersiz. Lütfen daha sonra tekrar deneyin.')
        break
      case 'validation':
        if (frameRef.current?.contentWindow) {
          frameRef.current.contentWindow.postMessage('validated', '*')
        }
        break
    }
  }

  function redirectToIBBApp() {
    const currentMobile = window.isMobile()

    let platform = 'Unknown'

    if (isIOS) {
      platform = 'iOS'
    } else if (isAndroid) {
      platform = 'Android'
    } else if (isWindows) {
      platform = 'Windows'
    } else if (isMacOs) {
      platform = 'Mac OS'
    }

    if (!currentMobile) {
      window.open('https://bireysel.istanbulkart.istanbul/')
    } else if (platform === 'iOS') {
      window.open(
        'https://apps.apple.com/tr/app/i-stanbulkart-dijital-kart%C4%B1m/id1352307391?l=tr'
      )
    } else if (platform === 'Android') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.belbim.istanbulkart&gl=TR'
      )
    } else {
      window.open('https://bireysel.istanbulkart.istanbul/')
    }
  }

  useEffect(() => {
    window.onmessage = (event) => {
      console.log(event?.data, 'event')
      handleMessage(event?.data)
    }
  }, [])

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={() => console.log('asd')}
    >
      <div className={c.container}>
        <div className={c.header}>
          <div className={c.back} onClick={onClose}>
            <img src={headerArrowIcon} className={c.arrow} alt='geri' />
          </div>
        </div>

        <div className={c.title}>
          Dijital bakiyen hangi kimlik numarasına yüklensin?
        </div>
        <div className={c.message}>
          İstanbulkart bakiyesinin yükleneceği kimlik numarasını yazın.
        </div>

        <div className={c.frameContainer}>
          <iframe
            onChange
            ref={frameRef}
            className={c.tcknContainer}
            width={300}
            height={200}
            src={data.data.Message}
          ></iframe>
        </div>

        <Button className={c.submitButton} textButton onClick={onClose}>
          VAZGEÇ
        </Button>
      </div>
    </ModalTemplate>
  )
}
