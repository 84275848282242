import React, {useRef, useEffect} from 'react'
import classcat from 'classcat'

import {isChild} from 'src/helpers/domHelper'
import c from './modalTemplate.module.css'

export default function ModalTemplate({
  children,
  onClose,
  containerClass,
  containerRef,
  contentClass,
  contentRef,
  closeClass,
  isDrawer,
  preventCloseOnClickOtside,
  dontClosable = false,
  className
}) {
  const modal = useRef()
  const content = useRef()

  var animating = false,
    diff = 0

  isDrawer = window.isMobile() && isDrawer

  useEffect(() => {
    if (containerRef) containerRef.current = modal.current

    if (contentRef) contentRef.current = content.current

    document.body.style.overflow = 'hidden'

    if (isDrawer) content.current.style.bottom = '0px'
    else modal.current.style.opacity = 1

    return () => (document.body.style.overflow = 'initial')
  }, []) //eslint-disable-line

  function handleMouseDown(e) {
    if (!content.current) return

    if (animating || !window.isMobile()) return

    // console.log("handleMouseDown", content.current)
    // window.clearTimeout(autoSlideTimeout);s

    //farenin tıklandıktan sonra başladığı noktayı alıyor
    var startY = e.pageY
    if (!e.pageY && e.touches) startY = e.touches[0].pageY

    //pencere'nin mevcut uzunluğu alınıyor
    const winH = window.innerHeight

    //farenin başlangıcı ile bitişi arasındaki farkı hesaplamak için
    diff = 0

    //mouse down yapıldığı zaman mouse move event'i ekleniyor
    window.ontouchmove = handleSliderMove
    window.onmousemove = handleSliderMove
    window.onmouseup = handleSliderMouseUp
    window.ontouchend = handleSliderMouseUp

    function handleSliderMove(e) {
      //farenin tıklandıktan sonra başladığı noktayı alıyor
      //Fare hareket ederken bulunduğu mevcut nokta değişkene atanıyor
      var y = e.pageY
      if (!e.pageY && e.touches) y = e.touches[0].pageY

      //farenin başlangıç noktası ile bitiş noktası arasındaki farkı hesaplıyor
      diff = ((startY - y) / winH) * 70

      //Fare hareket ederken, fareye tıklamayı bitirene kadar pencerenin takip etmesi için bu işlem yapılıyor
      // diff /= 2;
      if (diff > 0) diff = 0
      content.current.style.transform = 'translate3d(0, ' + -diff + '%,0)'
    }
  }

  function handleSliderMouseUp(e) {
    window.onmousemove = null
    window.ontouchmove = null
    window.onmousemove = null
    window.onmouseup = null
    window.ontouchend = null

    if (animating) return

    console.log('diff', diff < -8)

    //eğer fare yeteri kadar hareket etmemiş ise slider mevcut pozisyonuna getirilecek
    if (diff < -8) {
      animating = true
      content.current.style.transition = 'transform 0.3s'
      content.current.style.transform = 'translate3d(0, 100%, 0)'

      setTimeout(() => {
        onClose()
      }, 300)
      return
    } else {
      content.current.style.transform = 'none'
    }
  }

  function closeContent() {
    return {__html: '&times'}
  }

  return (
    <div
      ref={modal}
      className={classcat([c.modal, containerClass, isDrawer && c.drawer])}
      onClick={(e) => {
        if (!isChild(e.target, 'modal-content') && !preventCloseOnClickOtside)
          onClose()
      }}
    >
      <div
        ref={content}
        className={classcat([c.content, contentClass])}
        data-block={true}
        id='modal-content'
        onMouseDown={isDrawer ? handleMouseDown : null}
        onTouchStart={isDrawer ? handleMouseDown : null}
      >
        {isDrawer && <div className={c.drawerThick} />}

        {!dontClosable && <div
          className={classcat([c.close, closeClass])}
          onClick={onClose}
          dangerouslySetInnerHTML={closeContent()}
        /> }
        {children}
      </div>
    </div>
  )
}
