function defaultModal(state = false, action) {
  switch (action.type) {
    case 'SET_DEFAULT_MODAL':
      return action.payload
    default:
      return state
  }
}

export default defaultModal
