import React from 'react'

import Button from 'src/components/Button'
import ModalTemplate from 'src/components/ModalTemplate'

import c from './walletLogin.module.css'

import walletLoginPopupTopIcon from 'src/assets/images/wallet-login-popup/top.png'
import walletLoginPopupCoinIcon from 'src/assets/images/wallet-login-popup/coin.png'
import walletLoginPopupProductIcon from 'src/assets/images/wallet-login-popup/product.png'
import walletLoginPopupGiftIcon from 'src/assets/images/wallet-login-popup/gift.png'

export default function WalletLogin({onClose, navigate}) {
  return (
    <ModalTemplate
      containerClass={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={onClose}
    >
      <img
        src={walletLoginPopupTopIcon}
        className={c.coverImage}
        alt='loginPopTop'
      />

      <div className={c.title}>ÖDÜLLER</div>
      <div className={c.subtitle}>
        Ödülleri görmek için Giriş Yapın Ya da Üye Olun!
      </div>
      <Button
        className={c.button}
        text='GİRİŞ YAP'
        onClick={() => {
          navigate('/giris-yap')
          onClose()
        }}
      />
      <Button
        className={c.button}
        text='ÜYE OL'
        secondary
        onClick={() => {
          navigate('/uye-ol')
          onClose()
        }}
      />
      <div className={c.bottom}>
        <span className={c.bottomText}>
          Kazanabileceğin sürprizler ve puanlar seni bekliyor
        </span>
        <div className={c.productField}>
          <div className={c.product}>
            <div>
              <img
                style={{width: '60%'}}
                src={walletLoginPopupCoinIcon}
                alt='coin'
              />
            </div>
            <span>Puanlar</span>
          </div>

          <div className={c.product}>
            <div>
              <img
                style={{width: '80%'}}
                src={walletLoginPopupProductIcon}
                alt='product'
              />
            </div>
            <span>Ürünler</span>
          </div>

          <div className={c.product}>
            <div>
              <img
                style={{width: '70%'}}
                src={walletLoginPopupGiftIcon}
                alt='gift'
              />
            </div>
            <span>Fırsatlar</span>
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}
