import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classcat from 'classcat'

import ModalTemplate from 'src/components/ModalTemplate'
import Loading from 'src/components/Loading'

import {setGifts} from 'src/actions/data'
import {setLoading} from 'src/actions/element'
import {handleError} from 'src/helpers/errorHandler'
import sendRequest from 'src/helpers/service'

import c from './vendorList.module.css'

import background from 'src/assets/images/vendor-list-top.png'

export default function VendorList({onClose, PromotionID}) {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const headers = useSelector((state) => state.data)
  const [vendors, setVendors] = useState(false)

  useEffect(() => {
    sendRequest
      .post('electra/api/promo/getPromotion', {
        CustomerID: user.CustomerID,
        PromotionID,
      })
      .then((data) => {
        setVendors(data[0].Retailers)
      })
      .catch((err) => {
        handleError(err, dispatch)
        onClose()
      })
  }, []) //eslint-disable-line

  const handleParticipate = (vendor) => {
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/ParticipatePromotionSeasonOpening', {
        CustomerID: user.CustomerID,
        RetailerID: vendor.RetailerID,
      })
      .then(() => {
        dispatch(setGifts(false))
        window.navigate('/biriktirdiklerim')
        onClose()
      })
      .catch((err) => {
        console.log('error: ', err)
        handleError(err, dispatch)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  if (!vendors) return <Loading />

  return (
    <ModalTemplate
      containerClass={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={onClose}
    >
      <div
        className={c.background}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        {headers.VendorList_Title || 'SÜRPRİZİNİ ALACAĞIN TEDARİKÇİYİ SEÇ'}
      </div>
      <div className={c.vendorList}>
        {vendors.map((vendor) => {
          const disabled = vendor.Quantity <= 0

          return (
            <div
              className={classcat([c.vendor, disabled && c.disabledVendor])}
              key={'vendor-item-' + vendor.RetailerID}
              onClick={() => handleParticipate(vendor)}
            >
              <div className={c.vendorTop}>
                <img src={vendor.Image} alt='vendorImage' />
                <div className={c.vendorContent}>
                  <div>{vendor.Title}</div>
                  <span className={c.vendorText}>{vendor.ButtonText}</span>
                </div>
              </div>
              <div className={c.vendorBottom}>
                <span>
                  {disabled ? 'Ürünün stoğu bitmiştir.' : vendor.Description}
                </span>
                {disabled && (
                  <div className={c.closedField}>
                    <div className={c.dot} />
                    Kapalı
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </ModalTemplate>
  )
}
