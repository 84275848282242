export const setUser = (value) => {
  value.CustomerID = localStorage.getItem('c_i')

  return {
    type: 'SET_USER',
    payload: value,
  }
}

export const logout = () => {
  sessionStorage.clear()
  return {
    type: 'LOGOUT',
    payload: false,
  }
}
