import React from 'react'
import {useSelector} from 'react-redux'

import Animation from './../LoadingAnimation'

function Loading() {
  const loading = useSelector((selector) => selector.elements.loading)

  if (loading) {
    return (
      <div style={{zIndex: 25521}}>
        <Animation />
      </div>
    )
  } else return null
}

export default Loading
