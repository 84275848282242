import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'

import Main from './main'
import DefaultModal from './components/DefaultModal'
import Modal from './components/Modal'
import LoadingAnimation from './components/LoadingAnimation'
import Loading from './components/Loading'

import './index.css'
import store from './store'
// import reportWebVitals from './reportWebVitals';

window.isMobile = function () {
  return window.innerWidth < 600
}

window.setTitle = function (title) {
  document.title = title + ' | Algida ile Kazan'
}


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense fallback={<LoadingAnimation />}>
        <Main />
        <Loading />
        <DefaultModal />
        <Modal />
      </React.Suspense>
    </React.StrictMode>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
