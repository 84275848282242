import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
  setDefaultModal,
  setLoading,
  setModal,
} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {handleError} from 'src/helpers/errorHandler'

import cm from './gift.module.css'

import {setGifts} from 'src/actions/data'
import {useNavigation} from 'react-router-dom'
const Gift = ({product, RetailerID, RetailerData, RetailerSelectionTypeID}) => {
  const headers = useSelector((state) => state.data.headers)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  // const nav=useNavigation()

  const handleSuccess = () => {
    dispatch(setGifts(false))
    dispatch(setModal(false))
    dispatch(setDefaultModal(false))
    setTimeout(() => {
      window.navigate('/biriktirdiklerim')
    }, 10)
  }

  const handleSubmit = () => {
    dispatch(setLoading(true))
    let reqBody = {}
    if (RetailerData.RetailerSelectionTypeID === 0) {
      reqBody = {
        CustomerID: user.CustomerID,
        PromotionID: product.PromotionID,
        GiftID: product.GiftID,
      }
    } else if (RetailerData.RetailerSelectionTypeID === 1) {
      reqBody = {
        CustomerID: user.CustomerID,
        PromotionID: product.PromotionID,
        RetailerID: RetailerID,
        GiftID: product.GiftID,
      }
    }
    sendRequest
      .post('electra/api/promo/ParticipatePromotionSeasonOpening', reqBody)
      .then((data) => {
        dispatch(setLoading(false))
        if (data[0].ResponseCode === 0) {
          dispatch(
            setDefaultModal({
              title:
                headers.supplierGiftSelectionSuccessPopupTitle ||
                'Teşekkür Ederiz',
              message:
                headers.supplierGiftSelectionSuccessPopupMessage ||
                'Katılımınız alınmıştır.',
              button: 'TAMAM',
              onClick: handleSuccess,
              onClose: () => {
                dispatch(setGifts(false))
                dispatch(setDefaultModal(false))
                window.navigate('/biriktirdiklerim')
              },
            })
          )
        } else {
          dispatch(setLoading(false))
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(setLoading(false))
        handleError(error, dispatch)
      })
  }

  const handleClick = () => {
    if (
      RetailerData.RetailerSelectionTypeID === 0 ||
      RetailerData.RetailerSelectionTypeID === 1
    ) {
      dispatch(
        setDefaultModal({
          title: product.GiftName,
          message:
            headers.supplierGiftSelectionPopupMessage ||
            'Bu ürünü almak istediğinize emin misiniz?',
          button: headers.supplierGiftSelectionPopupButton || 'Katıl',
          onClick: handleSubmit,
        })
      )
    } else if (RetailerData.RetailerSelectionTypeID === 2) {
      dispatch(setLoading(true))
      sendRequest
        .post('electra/api/promo/GetPromotionRetailers', {
          CustomerID: user.CustomerID,
          PromotionID: product.PromotionID,
          GiftID: product.GiftID,
        })
        .then((data) => {
          setTimeout(() => {
            let obj = {
              ...RetailerData,
              RetailerSelectionTypeID: 2,
              selectingRetailer: 'true',
              Retailers: data,
            }
            dispatch(
              setModal({
                type: 'supplier',
                Props: obj,
              })
            )
          }, 100)
          dispatch(setLoading(false))
        })
        .catch((error) => {
          console.log(error)
          dispatch(setLoading(false))
          handleError(error, dispatch)
        })
    }
  }

  return (
    <div
      onClick={() => {
        product.Quantity > 0 && handleClick()
      }}
      className={product.Quantity < 1 ? cm.stockOutContainer : cm.container}
    >
      <img className={cm.image} src={product.ImageUrl} alt='productImage' />
      <div className={cm.name}>{product.GiftName}</div>
      <div className={cm.bottom}>
        {product.Quantity > 0 && (
          <span className={cm.amount}>
            {headers.supplierGiftBottom || '1 Alana 1 Bedava'}
          </span>
        )}
        {product.Quantity < 1 && (
          <span className={cm.stockOutText}>
            {headers.supplierGiftBottomNoStock || 'Stoklar tükenmiştir.'}
          </span>
        )}
      </div>
    </div>
  )
}

export default Gift
