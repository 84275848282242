import React from 'react'
import c from './magnumbutton.module.css'

export const MagnumButton = ({onPress, title}) => {
  return (
    <button onClick={onPress} className={c.buttonContainer}>
      <b className={c.buttonText}>{title}</b>
    </button>
  )
}
