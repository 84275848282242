import {setErrorModal} from '../actions/element'

export function handleError(error, instance, errorCode) {
  let title, message

  let data = error && error.response && error.response.data
  if (Array.isArray(data)) data = data[0]

  if (data) {
    if (data.Title) title = data.Title

    if (data.Message) message = data.Message
    else if (errorCode)
      message = 'Beklenmedik bir hata oluştu (hata kodu: ' + errorCode + ')'
  }

  instance(
    setErrorModal({
      title,
      message,
    })
  )
}
