import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ModalTemplate from 'src/components/ModalTemplate'
import Gift from './Gift'

import {setLoading} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {handleError} from 'src/helpers/errorHandler'

import c from './giftList.module.css'

export default function GiftList({onClose, PromotionID, RetailerData}) {
  const dispatch = useDispatch()
  const headers = useSelector((state) => state.data.headers)
  const [gifts, setGifts] = useState()
  const [ready, setReady] = useState(false)
  const user = useSelector((state) => state.user)

  const fetchSuppliers = () => {
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/GetPromotionGifts', {
        CustomerID: user.CustomerID,
        PromotionID: PromotionID,
        RetailerID: RetailerData.RetailerID,
      })
      .then((data) => {
        setGifts(data[0].Gifts)
        setReady(true)
        dispatch(setLoading(false))
      })
      .catch((error) => {
        handleError(error, dispatch)
      })
  }

  useEffect(() => {
    fetchSuppliers()
  }, []) //eslint-disable-line

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.container}
      closeClass={c.close}
      isDrawer={false}
      onClose={() => {
        onClose()
      }}
      // popupStyle={{background: loading && "white"}}
    >
      <div className={c.top}>
        <h3 className={c.title}>
          {headers.supplierGiftListTitle || '1 Alana 1 Bedava'}
        </h3>
        <h5 className={c.subtitle}>{headers.supplierGiftListTitle || ''}</h5>
      </div>
      <div className={c.content}>
        {ready &&
          gifts.map((product, index) => {
            return (
              <Gift
                key={product.GiftID}
                product={product}
                RetailerID={RetailerData.RetailerID}
                RetailerData={RetailerData}
              />
            )
          })}
      </div>
    </ModalTemplate>
  )
}
