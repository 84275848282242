import React, {useState} from 'react'
import classcat from 'classcat'

import c from './input.module.css'

const Input = ({
  id,
  label,
  value,
  style,
  onChange,
  type = 'text',
  select,
  onKeyDown,
  className,
  warn,
  warning,
  isMagnum = false,
}) => {
  const [focused, setFocused] = useState(false)

  return (
    <div
      id={id}
      style={style}
      className={classcat([
        c.container,
        className,
        type === 'date' && c.blockLabel,
      ])}
    >
      <div
        style={{borderBottomColor: warn ? 'red' : 'lightgray'}}
        className={c.input}
      >
        {label && (
          <label
            className={classcat([(focused || value.length !== 0) && c.focused])}
          >
            {label}
          </label>
        )}
        <input
          type={type}
          disabled={select}
          maxLength={!label ? 13 : ''}
          value={value}
          onKeyDown={onKeyDown}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className={c.text}
          style={{color: isMagnum && 'white'}}
        />
      </div>
      {warn && <small className={c.warning}>{warning}</small>}
    </div>
  )
}

export default Input
