function user(state = false, action) {
  switch (action.type) {
    case 'SET_USER':
      return action.payload
    case 'LOGOUT':
      return false
    default:
      return state
  }
}

export default user
