const defaultState = {
  win: 'none',
}
function modal(state = defaultState, action) {
  switch (action.type) {
    case 'SET_WIN_STATUS':
      return {
        ...state,
        win: action.payload,
      }
    default:
      return state
  }
}
export default modal
