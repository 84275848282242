import React, { useCallback, useEffect } from 'react'
import c from './magnumfaq.module.css';
import leftBack from 'src/assets/images/magnum/magnumBackLeft.png'
import Collapsible from 'src/components/Collapsible';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export const MagnumFaq = () => {
  const dispatch = useDispatch()
  const headers = useSelector((state) => state.data.headers)
  const user = useSelector((state) => state.user)
  useEffect(() => {
  }, []) 

  const handleOpen = useCallback((title) => {
    window.logEvent({
      event: 'magnumSSS',
      sss_title: title,
      UserID: user.CustomerID,
    })
  }, [user.CustomerID])

  return (
    <div className={c.container}>
      <div className={c.magnumHeaderCard}>
        <b className={c.magnumHeaderTextTwo}>{headers.yardimMagnum.SSS.title || 'SIKÇA SORULAN SORULAR'}</b>
      </div>

      <div className={c.faqContainer}>
        <Link to={'/magnum'} className={c.leftBackButton}>
          <img src={leftBack} className={c.leftBack} />
        </Link>

        <div className={c.listContainer}>
          <b className={c.listTitle}>{headers.yardimMagnum.SSS.title || 'Sıkça Sorulan Sorular'}</b>

          <div className={c.listCard}>
            {!headers.yardimMagnum.SSS.text ? [] : headers.yardimMagnum.SSS.text.map((e,i) => {
              return(
                <Collapsible index={i} themeMagnum={true} title={e.title} text={e.text} onOpen={handleOpen} />
              )
            })}
          </div>

        </div>
      </div>
    </div>
  )
}
