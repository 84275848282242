export function isChild(el, parentId) {
  let isChild = false

  if (el.id === parentId) {
    //is this the element itself?
    isChild = true
  }

  while ((el = el.parentNode)) {
    //eslint-disable-line
    if (el.id == parentId) {
      //eslint-disable-line
      isChild = true
    }
  }

  return isChild
}
