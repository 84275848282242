import React, {useCallback, useEffect, useState} from 'react'
import classcat from 'classcat'
import {Link} from 'react-router-dom'
import {useNavigate, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {setModal} from 'src/actions/element'

import c from './header.module.css'

import userIcon from 'src/assets/images/header/user.svg'
import coinIcon from 'src/assets/images/header/coin.png'
import algidaLogoWhite from 'src/assets/images/algida-logo-white.png'
import sendRequest from 'src/helpers/service'
import magnumLogo from 'src/assets/images/magnum/magnumLogo.png'

export default function Header() {
  const dispatch = useDispatch()
  const headerProps = useSelector((state) => state.elements.header)
  const user = useSelector((state) => state.user)

  const [draws, setDraws] = useState([])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    sendRequest
      .post('electra/api/promo/GetRaffles', {
        CustomerID: user.CustomerID,
      })
      .then((res) => {
        // console.log(JSON.stringify(res.data.surveys))
        // console.log('@merto',res);
        setDraws(res)
      })
      .catch((err) => {
        console.log(err)
      })

    window.logEvent({
      event: 'screen_view',
      screen_name: location.pathname,
    })
  }, [location.pathname])

  function redirectToWallet() {
    if (!user) {
      dispatch(
        setModal({
          type: 'wallet-login',
          navigate: (path) => navigate(path),
        })
      )
    } else {
      navigate('/biriktirdiklerim')
    }
  }

  function redirectToAwards() {
    if (!user) {
      dispatch(
        setModal({
          type: 'wallet-login',
          navigate: (path) => navigate(path),
        })
      )
    } else {
      navigate('/oduller', {
        state: {
          active: true,
        },
      })
    }
  }

  function redirectToCampaigns() {
    if (!user) {
      dispatch(
        setModal({
          type: 'draw-login',
          navigate: (path) => navigate(path),
        })
      )
    } else {
      navigate('/kampanyalar')
    }
  }

  const redirectToHome = () => {
    if (!user) {
      dispatch(
        setModal({
          type: 'home-login',
          navigate: (path) => navigate(path),
        })
      )
    } else {
      navigate('/')
    }
  }

  function redirectToDraw() {
    if (!user) {
      dispatch(
        setModal({
          type: 'draw-login',
          navigate: (path) => navigate(path),
        })
      )
    } else {
      navigate('/cekilisler')
    }
  }

  const handleAlgidaClick = useCallback(() => {
    window.logEvent({
      event: 'magnum_button',
      button_name: 'Algida',
      UserID: user.CustomerID,
    })
  }, [user.CustomerID])

  if (!headerProps) return false

  if (location.pathname === '/magnum' || location.pathname === '/magnumFaq') {
    return (
      <nav className={c.navMagnum}>
        <div className={c.content}>
          <div className={c.left}>
            {headerProps.onBack && (
              <div className={c.backButton} onClick={headerProps.onBack}>
                <div className={c.backArrow} />
              </div>
            )}

            {user && (
              <div
                className={classcat([
                  c.pointField,
                  c.desktopPointField,
                  c.pointerShadowMagnum,
                ])}
                style={{cursor: 'pointer'}}
                onClick={redirectToHome}
              >
                <img src={coinIcon} alt='coin' />
                {user.Points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </div>
            )}

            {!user && (
              <Link
                to='/giris-yap'
                id='header-login-button'
                className={classcat([
                  c.loginButton,
                  headerProps.onBack && c.hideOnMobile,
                ])}
              >
                <img src={userIcon} alt='user' />
                Giriş Yap
              </Link>
            )}

            {user && !(headerProps.onBack && window.isMobile()) && (
              <Link to='/profil' className={c.userField}>
                <img src={userIcon} alt='user' className={c.userFieldMagnum} />
                <div>
                  <span>Merhaba</span>
                  <b>{user.Name}</b>
                </div>
              </Link>
            )}
          </div>

          <div className={c.buttonContainerMagnum}>
            <img src={magnumLogo} className={c.magnumLogo} alt='magnum-logo' />
            <div className={c.magnumText}>MAGNUM DÜNYASI</div>
          </div>

          <div
            style={{
              width: headerProps.title && window.isMobile() ? '100%' : '33%',
            }}
            className={c.centerMagnum}
          >
            <Link to='/' onClick={handleAlgidaClick}>
              <div className={c.magnumAlgidaCard}>
                <img
                  className={c.magnumAlgidaLogo}
                  src={algidaLogoWhite}
                  alt='algida-logo'
                />
              </div>
            </Link>
          </div>

          <div className={c.right}>
            {user && (
              <div className={c.pointField}>
                {/* <img src={coinIcon} alt='coin' /> */}
                {user.Points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </div>
            )}
          </div>
        </div>
      </nav>
    )
  }

  return (
    <nav className={c.nav}>
      <div className={c.content}>
        <div className={c.left}>
          {headerProps.onBack && (
            <div className={c.backButton} onClick={headerProps.onBack}>
              <div className={c.backArrow} />
            </div>
          )}

          {user && (
            <div
              className={classcat([
                c.pointField,
                c.desktopPointField,
                c.pointerShadow,
              ])}
              style={{cursor: 'pointer'}}
              onClick={redirectToHome}
            >
              <img src={coinIcon} alt='coin' />
              {user.Points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </div>
          )}

          {!user && (
            <Link
              to='/giris-yap'
              id='header-login-button'
              className={classcat([
                c.loginButton,
                headerProps.onBack && c.hideOnMobile,
              ])}
            >
              <img src={userIcon} alt='user' />
              Giriş Yap
            </Link>
          )}

          {user && !(headerProps.onBack && window.isMobile()) && (
            <Link to='/profil' className={c.userField}>
              <img src={userIcon} alt='user' />
              <div>
                <span>Merhaba</span>
                <b>{user.Name}</b>
              </div>
            </Link>
          )}
        </div>

        <div
          style={{
            width: headerProps.title && window.isMobile() ? '100%' : '33%',
          }}
          className={c.center}
        >
          {headerProps.title && window.isMobile() ? (
            <span className={c.title}>{headerProps.title}</span>
          ) : (
            <Link to='/'>
              <img className={c.logo} src={algidaLogoWhite} alt='algida-logo' />
            </Link>
          )}
        </div>

        <div className={c.buttonContainer}>
          <Link
            to='/'
            className={classcat([
              c.navButton,
              location.pathname === '/' && c.activeButton,
            ])}
          >
            Anasayfa
          </Link>

          <button
            className={classcat([
              c.navButton,
              location.pathname === '/kampanyalar' && c.activeButton,
            ])}
            onClick={redirectToCampaigns}
          >
            Kampanyalar
          </button>

          <button
            className={classcat([
              c.navButton,
              location.pathname === '/oduller' && c.activeButton,
            ])}
            onClick={redirectToAwards}
          >
            Ödüller
          </button>

          {user && 
            <button
              className={classcat([
                c.navButton,
                location.pathname === '/biriktirdiklerim' && c.activeButton,
              ])}
              onClick={redirectToWallet}
            >
              Cüzdan
            </button>
          }

          {/* {draws && draws?.length >= 1 && (
            <button
              className={classcat([
                c.navButton,
                location.pathname === '/cekilisler' && c.activeButton,
              ])}
              onClick={redirectToDraw}
            >
              Çekilişler
            </button>
          )} */}
          {/* <Link
            to='/firsatlar'
            className={classcat([
              c.navButton,
              location.pathname === '/firsatlar' && c.activeButton,
            ])}
          >
            Fırsatlar
          </Link>   */}
        </div>

        <div className={c.right}>
          {user && (
            <div className={c.pointField}>
              <img src={coinIcon} alt='coin' />
              {user.Points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}
